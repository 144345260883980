import React , {useRef, useState} from 'react'
import {CardGroup, Card, Form, Button, Alert} from 'react-bootstrap'
import {useAuth} from '../../Context/AuthContext'
import {Link, useHistory} from 'react-router-dom';
import { getStorage, getDownloadURL, ref as storageRef } from "firebase/storage";
import {storage, database} from '../../firebase'
import { getDatabase,get, child, ref as databaseRef } from "firebase/database";

export default function Profile() {
  const [error, setError] = useState('')
  const {currentUser, logout} = useAuth()
  const history = useHistory()
  async function handleLogout() {
    setError('')
    try {
      await logout()
      history.push('/login')
    } catch {
      setError('Failed to logout')
    }
  }
  var currentName
  var currentSocial
  const dbRef = databaseRef(database);
  get(child(dbRef, `users/${currentUser.uid}`)).then((snapshot) => {
    if (snapshot.exists()) {
      // console.log(snapshot.val().name);
      document.getElementById('name').innerText = snapshot.val().name;
      currentName = snapshot.val().name
      // console.log(snapshot.val().social);
      document.getElementById('social').innerText = snapshot.val().social;
      currentSocial = snapshot.val().social
      // console.log(snapshot.val().display);
    } else {
    }
  }).catch((error) => {
    console.error(error);
  });

  const pathReference = storageRef(storage, 'base64/'+currentUser.uid);
  getDownloadURL(pathReference)
  .then((url) => {
    console.log(url);
    const img = document.getElementById('myimg');
    img.setAttribute('src', url);
  })
  console.log(currentName);

  return (
    <>
      <Card>
      {error && <Alert className = "alert" variant = 'danger'>{error}</Alert>}
        <Card.Body className = "signup-container">
          <Card.Title className = "signup-title">My Profile</Card.Title>
          <Card.Body className = "profile-content">
          <div class = "img-container"><img id = "myimg"/></div>
          <div><strong>Email: </strong> {currentUser.email}</div>
          <div><strong>Name: </strong> <span id = "name"></span></div>
          <div><strong>IG Handle: </strong> <span id = "social"></span></div>
          <div className = "logout-container">
          <div className = 'update-link'><Link className = "redirect" to = '/userinfo'>Update my Profile</Link></div>
          <Button className = "logout" variant = "link" onClick = {handleLogout}>Log Out</Button>
          </div>
          </Card.Body>
        </Card.Body>
      </Card>
    </>
  );
}
// <div className = 'update-link'><Link className = "redirect" to = '/userinfo'>Update my Profile</Link></div>
