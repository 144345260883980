// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import {getAuth} from 'firebase/auth';
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  // appId: process.env.REACT_APP_FIREBASE_APP_ID,
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  apiKey: "AIzaSyCZxr79F9MaJISLInOEqkh6JcY4JoX9WkE",
  authDomain: "nfme-8e09e.firebaseapp.com",
  projectId: "nfme-8e09e",
  storageBucket: "nfme-8e09e.appspot.com",
  messagingSenderId: "964532138483",
  appId: "1:964532138483:web:0067a03028087030e0e286",
  databaseURL: "https://nfme-8e09e-default-rtdb.firebaseio.com/",
  measurementId: "G-EP7BFT5SEG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default app
export const storage = getStorage(app);
export const auth = getAuth(app)
export const database = getDatabase(app);
