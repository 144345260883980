import React , {useRef, useState} from 'react'
import {CardGroup, Card, Form, Button, Alert} from 'react-bootstrap'
import {useAuth} from '../../Context/AuthContext'
import {Link, useHistory} from 'react-router-dom'
import Profile from './Profile'
import { GoogleLogin } from 'react-google-login';


export default function Signup() {
  const nameRef = useRef()
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { signup, googlelogin} = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  async function handleSubmit(e) {
    e.preventDefault()

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError('Passwords do not match!')
    }
    // if (!emailRef.current.value.includes('.edu')) {
    //   return setError('Email must be a .edu account')
    // }
    try {
      setError('')
      setLoading(true)
      await signup(emailRef.current.value, passwordRef.current.value)
      history.push('/profile')
    } catch {
      return setError('Failed to Create an Account')
    }
    setLoading(false)
  }

  async function handleGoogleSubmit(e) {
    e.preventDefault()

    try {
      setError('')
      setLoading(true)
      await googlelogin()
      history.push('/')
    } catch {
      return setError('Login with Google failed')
    }
    setLoading(false)
  }

  return (
    <>
      <Card>
        <Card.Body className = "signup-container">
          <Card.Title className = "signup-title">Create an Account</Card.Title>
          {error && <Alert className = "alert" variant = 'danger'>{error}</Alert>}
          <Form onSubmit = {handleSubmit}>
            <Form.Group id = "email">
              <Form.Control type = "email" ref = {emailRef} required placeholder = "Email"/>
            </Form.Group>
            <Form.Group id = "password">
              <Form.Control type = "password" ref = {passwordRef} required placeholder = "Password"/>
            </Form.Group>
            <Form.Group id = "password">
              <Form.Control type = "password" ref = {passwordConfirmRef} required placeholder = "Confirm Password"/>
            </Form.Group>
            <div className = "submit-container">
              <Button disabled = {loading} type = "submit" className = "signup-button">SIGN UP</Button>
            </div>
          </Form>
          <div>Already have an account? Login <Link className = "redirect" to = '/login'>here</Link></div>
          <div className = "login-container">
          <GoogleLogin
            clientId="964532138483-hlvv33h2boriib6dncsgrpp8i67nmtht.apps.googleusercontent.com"
            render={renderProps => (
            <button className = "googleButton" onClick={handleGoogleSubmit}>
            <img className="btnimg" src="./google-button-light.svg" alt="" />
            <p className = "btntxt">Sign in with Google</p>
            </button>
          )}
          />
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
