import React, {useState} from 'react';
import './grid.css';
import {LoadFace, LoadEyes} from './LoadHelpers.js'

export default function Load(props) {
  var cells = props.cells
  cells = (<LoadFace cells = {cells}
  />)
  return (
    cells
  );
}
