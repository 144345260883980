import React from 'react';
import Pixel from './Pixel.js';
import './grid.css';
import {useDesign} from '../../../Context/DesignContext'
import {LoadTallAfro, LoadMohawk, LoadAfro, Load1Curl,
LoadMiddlePart, LoadBun, LoadHighPonyTail1, LoadHighPonyTail2,
LoadRightLongHair, LoadLeftLongHair, LoadLongHair, LoadShoulderLongHair,
LoadWavyLongHair, LoadShortHair, LoadWavyShortHair, Load4Curl,
Load4Curl2, Load1Curl2, Load3Curl, Load2Curl, LoadSidePart
} from "./LoadHair.js";
import {curl4Pixels, curl4Pixels2, curl1Pixels2, curl3Pixels, curl2Pixels, sidePartPixels, wavyShortHairPixels} from './HairData'


export function LoadFace(props){
  const {currHairStyle, updateHairStyle, currHairColor, updateHairColor, currSkinTone,
  updateSkinTone, currEyeStyle, updateEyeStyle, currEyeColor, updateEyeColor,
  currNose, updateNose,currMouth, updateMouth, currShirt, updateShirt, currHat, updateHat,
  currBackground, updateBackground, currFace, dummyValue, dummy} = useDesign()
  // const {currSkinTone} = useDesign()
    var facePixels = [[25, 15],[24, 15],[23, 15],[22, 15],[21, 15],
    [20, 15],[19, 15],[18, 15],[17, 15],[17, 14],[16, 14],[15, 14],
    [14, 14],[25, 20],[24, 20],[23, 20],[22, 19],[23, 21],[23, 22],
    [23, 23],[22, 24],[21, 25],[20, 25],[19, 25],[18, 25],[17, 25],
    [16, 25],[15, 25],[14, 25],[13, 25],[14, 15],[13, 15],[12, 15],
    [11, 16],[10, 17],[9, 18],[9, 19],[9, 20],[9, 21],[9, 22],[10, 23],
    [11, 24],[12, 25]]
  var cells = props.cells
  for (var x = 0; x < facePixels.length; x++) {
    cells[facePixels[x][0]][facePixels[x][1]] = <Pixel key = {facePixels[x][0].toString()+facePixels[x][1].toString()} color = "#000000"/>
  }
  for (let x = 23; x < 26; x++) {
    for (let y = 16; y < 20; y++) {
      cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currSkinTone}`}/>
    }
  }
  for (let x = 14; x < 23; x++) {
    for (let y = 16; y < 19; y++) {
      cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currSkinTone}`}/>
    }
  }
  for (let x = 12; x < 22; x++) {
    for (let y = 16; y < 25; y++) {
      cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currSkinTone}`}/>
    }
  }
  for (let x = 21; x < 23; x++) {
    for (let y = 20; y < 24; y++) {
      cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currSkinTone}`}/>
    }
  }
  for (let x = 15; x < 17; x++) {
    cells[x][15] = <Pixel key = {x.toString()+'15'.toString()} color = {`#${currSkinTone}`}/>
  }
  for (let y = 17; y < 24; y++) {
    cells[11][y] = <Pixel key = {'11'.toString()+y.toString()} color = {`#${currSkinTone}`}/>
  }
  for (let y = 18; y < 23; y++) {
    cells[10][y] = <Pixel key = {'10'.toString()+y.toString()} color = {`#${currSkinTone}`}/>
  }
  cells = <LoadShirt cells = {cells}/>
  return (
      cells
  );
}

export function LoadShirt(props) {
  const {currShirt} = useDesign()
  const {currShirtColor} = useDesign()
    let color;
    if (currShirt === "color") {
      console.log(currShirtColor)
      color = `#${currShirtColor}`
    } else if (currShirt === "athelas"){
      color = "#ff6779"
    } else if (currShirt === "yale"){
      color = "#00356b"
    } else if (currShirt === "harv" ) {
      color = "#990000"
    } else if (currShirt === "corn") {
      color = "#B31B1B"
    } else if (currShirt === "prin") {
      color = "#000000"
    } else if (currShirt === "colu") {
      color = "#B9D9EB"
    } else if (currShirt === "penn") {
      color = "#011F5B"
    } else if (currShirt === "dart") {
      color = "#006633"
    } else if (currShirt === "brown") {
      color = "#4E3629"
    } else if (currShirt === "berk") {
      color = "#003262"
    } else if (currShirt === "stan") {
      color = "#8C1515"
    } else if (currShirt === "mit") {
      color = "#C2C0BF"
    } else if (currShirt === "cmu") {
      color = "#bb0000"
    } else if (currShirt === "ucla") {
      color = "#2774AE"
    } else if (currShirt === "uiuc") {
      color = "#13294b"
    } else if (currShirt === "umich") {
      color = "#00274C"
    } else if (currShirt === "a&m") {
      color = "#500000"
    } else if (currShirt === "duke") {
      color = "#00539B"
    } else if (currShirt === "uva") {
      color = "#232D4B"
    } else if (currShirt === "nyu") {
      color = "#57068c"
    } else if (currShirt === "utaust"){
      color = "#bf5700"
    } else if (currShirt === "usc"){
      color = "#990000"
    }

    var cells = props.cells
    for (let x = 26; x < 36; x++) {
      for (let y = 15; y < 21; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {color}/>
      }
    }

    for (let x = 28; x < 36; x++) {
      for (let y = 5; y < 32; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {color}/>
      }
    }
    cells = <LoadShirtDesign cells = {cells}/>
  return (
      cells
  );
}

export function LoadShirtDesign(props) {
  const {currShirt} = useDesign()
    var cells = props.cells
    if (currShirt === "color") {

    } else if (currShirt === "athelas"){
      for (let y = 16; y < 20; y++) {
        cells[30][y] = <Pixel key = {'30'.toString()+y.toString()} color = {"white"}/>
      }
      for (let y = 14; y < 22; y++) {
        cells[31][y] = <Pixel key = {'31'.toString()+y.toString()} color = {"white"}/>
      }
      for (let y = 13; y < 16; y++) {
        cells[32][y] = <Pixel key = {'32'.toString()+y.toString()} color = {"white"}/>
      }
      for (let y = 20; y < 23; y++) {
        cells[32][y] = <Pixel key = {'32'.toString()+y.toString()} color = {"white"}/>
      }
      for (let x = 33; x < 35; x++){
        for (let y = 12; y < 14; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let y = 11; y < 13; y++) {
        cells[35][y] = <Pixel key = {'35'.toString()+y.toString()} color = {"white"}/>
      }
      for (let x = 33; x < 35; x++){
        for (let y = 22; y < 24; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let y = 23; y < 25; y++) {
        cells[35][y] = <Pixel key = {'35'.toString()+y.toString()} color = {"white"}/>
      }
      
    } 
    else if (currShirt === "yale"){
      for (let y = 12; y < 16; y++) {
        cells[29][y] = <Pixel key = {'29'.toString()+y.toString()} color = {"white"}/>
      }
      for (let y = 20; y < 24; y++) {
        cells[29][y] = <Pixel key = {'29'.toString()+y.toString()} color = {"white"}/>
      }
      for (let x = 30; x < 34; x++) {
        for (let y = 13+x-30; y < 15+x-30; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
        for (let y = 21-x+30; y < 23-x+30; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 34; x < 36; x++) {
        cells[x][17] = <Pixel key = {x.toString()+'17'.toString()} color = {"white"}/>
        cells[x][18] = <Pixel key = {x.toString()+'18'.toString()} color = {"white"}/>
      }
    } else if (currShirt === "harv" ) {
      for (let y = 12; y < 16; y++) {
        cells[30][y] = <Pixel key = {'30'.toString()+y.toString()} color = {"white"}/>
      }
      for (let y = 20; y < 24; y++) {
        cells[30][y] = <Pixel key = {'30'.toString()+y.toString()} color = {"white"}/>
      }
      for (let y = 13; y < 22; y++) {
        cells[34][y] = <Pixel key = {'34'.toString()+y.toString()} color = {"white"}/>
      }
      for (let x = 30; x < 36; x++) {
        cells[x][13] = <Pixel key = {x.toString()+'13'.toString()} color = {"white"}/>
        cells[x][21] = <Pixel key = {x.toString()+'21'.toString()} color = {"white"}/>
        cells[x][14] = <Pixel key = {x.toString()+'14'.toString()} color = {"white"}/>
        cells[x][22] = <Pixel key = {x.toString()+'22'.toString()} color = {"white"}/>
      }
    } else if (currShirt === "corn") {
      for (let y = 15; y < 21; y++) {
        cells[30][y] = <Pixel key = {'30'.toString()+y.toString()} color = {"white"}/>
      }
      for (let y = 14; y < 22; y++) {
        cells[31][y] = <Pixel key = {'31'.toString()+y.toString()} color = {"white"}/>
      }
      for (let y = 13; y < 16; y++) {
        cells[32][y] = <Pixel key = {'32'.toString()+y.toString()} color = {"white"}/>
      }
      for (let x = 33; x < 36; x++){
        for (let y = 12; y < 15; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 32; x < 34; x++){
        for (let y = 20; y < 23; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
    } else if (currShirt === "prin") {
      for (let y = 14; y < 22; y++) {
        cells[29][y] = <Pixel key = {'29'.toString()+y.toString()} color = {"#f58025"}/>
      }
      for (let y = 14; y < 23; y++) {
        cells[30][y] = <Pixel key = {'30'.toString()+y.toString()} color = {"#f58025"}/>
      }
      for (let x = 31; x < 36; x++) {
        cells[x][15] = <Pixel key = {x.toString()+'15'.toString()} color = {"#f58025"}/>
        cells[x][16] = <Pixel key = {x.toString()+'16'.toString()} color = {"#f58025"}/>
      }
      for (let x = 31; x < 34; x++) {
        for (let y = 21; y < 23; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#f58025"}/>
        }
      }
      for (let y = 16; y < 22; y++) {
        cells[34][y] = <Pixel key = {'34'.toString()+y.toString()} color = {"#f58025"}/>
      }
    } else if (currShirt === "colu") {
      for (let y = 15; y < 21; y++) {
        cells[30][y] = <Pixel key = {'30'.toString()+y.toString()} color = {"white"}/>
      }
      for (let y = 14; y < 22; y++) {
        cells[31][y] = <Pixel key = {'31'.toString()+y.toString()} color = {"white"}/>
      }
      for (let y = 13; y < 16; y++) {
        cells[32][y] = <Pixel key = {'32'.toString()+y.toString()} color = {"white"}/>
      }
      for (let x = 33; x < 36; x++){
        for (let y = 12; y < 15; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 32; x < 34; x++){
        for (let y = 20; y < 23; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
    } else if (currShirt === "penn") {
      for (let y = 14; y < 22; y++) {
        cells[29][y] = <Pixel key = {'29'.toString()+y.toString()} color = {"#990000"}/>
      }
      for (let y = 14; y < 23; y++) {
        cells[30][y] = <Pixel key = {'30'.toString()+y.toString()} color = {"#990000"}/>
      }
      for (let x = 31; x < 36; x++) {
        cells[x][15] = <Pixel key = {x.toString()+'15'.toString()} color = {"#990000"}/>
        cells[x][16] = <Pixel key = {x.toString()+'16'.toString()} color = {"#990000"}/>
      }
      for (let x = 31; x < 34; x++) {
        for (let y = 21; y < 23; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#990000"}/>
        }
      }
      for (let y = 16; y < 22; y++) {
        cells[34][y] = <Pixel key = {'34'.toString()+y.toString()} color = {"#990000"}/>
      }
    } else if (currShirt === "dart") {
      for (let y = 14; y < 22; y++) {
        cells[29][y] = <Pixel key = {'29'.toString()+y.toString()} color = {"white"}/>
      }
      for (let y = 14; y < 23; y++) {
        cells[30][y] = <Pixel key = {'30'.toString()+y.toString()} color = {"white"}/>
      }
      for (let x = 31; x < 36; x++) {
        cells[x][15] = <Pixel key = {x.toString()+'15'.toString()} color = {"white"}/>
        cells[x][16] = <Pixel key = {x.toString()+'16'.toString()} color = {"white"}/>
      }
      for (let x = 31; x < 36; x++) {
        for (let y = 21; y < 23; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
    } else if (currShirt === "brown") {
      for (let y = 14; y < 22; y++) {
        cells[29][y] = <Pixel key = {'29'.toString()+y.toString()} color = {"white"}/>
      }
      for (let y = 14; y < 23; y++) {
        cells[30][y] = <Pixel key = {'30'.toString()+y.toString()} color = {"white"}/>
      }
      for (let x = 31; x < 36; x++) {
        cells[x][15] = <Pixel key = {x.toString()+'15'.toString()} color = {"white"}/>
        cells[x][16] = <Pixel key = {x.toString()+'16'.toString()} color = {"white"}/>
      }
      for (let x = 31; x < 34; x++) {
        for (let y = 21; y < 23; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let y = 16; y < 22; y++) {
        cells[34][y] = <Pixel key = {'34'.toString()+y.toString()} color = {"white"}/>
      }

      for (let y = 21; y < 23; y++) {
        cells[35][y] = <Pixel key = {'35'.toString()+y.toString()} color = {"white"}/>
      }
    } else if (currShirt === "berk") {
      for (let y = 14; y < 22; y++) {
        cells[29][y] = <Pixel key = {'29'.toString()+y.toString()} color = {"#FDB515"}/>
      }
      for (let y = 14; y < 23; y++) {
        cells[30][y] = <Pixel key = {'30'.toString()+y.toString()} color = {"#FDB515"}/>
      }
      for (let x = 31; x < 36; x++) {
        cells[x][15] = <Pixel key = {x.toString()+'15'.toString()} color = {"#FDB515"}/>
        cells[x][16] = <Pixel key = {x.toString()+'16'.toString()} color = {"#FDB515"}/>
      }
      for (let x = 31; x < 34; x++) {
        for (let y = 21; y < 23; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FDB515"}/>
        }
      }
      for (let y = 16; y < 22; y++) {
        cells[34][y] = <Pixel key = {'34'.toString()+y.toString()} color = {"#FDB515"}/>
      }

      for (let y = 21; y < 23; y++) {
        cells[35][y] = <Pixel key = {'35'.toString()+y.toString()} color = {"#FDB515"}/>
      }
    } else if (currShirt === "stan") {
      cells[29][18] = <Pixel key = {'29'.toString()+'18'.toString()} color = {"white"}/>
      for (let y = 17; y < 19; y++) {
        cells[30][y] = <Pixel key = {'30'.toString()+y.toString()} color = {"white"}/>
      }
      for (let y = 17; y < 20; y++) {
        cells[31][y] = <Pixel key = {'31'.toString()+y.toString()} color = {"white"}/>
      }
      for (let y = 16; y < 19; y++) {
        cells[32][y] = <Pixel key = {'32'.toString()+y.toString()} color = {"white"}/>
      }
      for (let y = 17; y < 20; y++) {
        cells[33][y] = <Pixel key = {'33'.toString()+y.toString()} color = {"white"}/>
      }
      for (let y = 16; y < 20; y++) {
        cells[34][y] = <Pixel key = {'34'.toString()+y.toString()} color = {"white"}/>
      }
      for (let y = 15; y < 21; y++) {
        cells[35][y] = <Pixel key = {'35'.toString()+y.toString()} color = {"white"}/>
      }
    } else if (currShirt === "mit") {
      for (let x = 30; x < 36; x++) {
        cells[x][10] = <Pixel key = {x.toString()+'10'.toString()} color = {"#A31F34"}/>
        cells[x][11] = <Pixel key = {x.toString()+'11'.toString()} color = {"#A31F34"}/>
      }
      for (let x = 30; x < 34; x++) {
        cells[x][13] = <Pixel key = {x.toString()+'13'.toString()} color = {"#A31F34"}/>
        cells[x][14] = <Pixel key = {x.toString()+'14'.toString()} color = {"#A31F34"}/>
      }
      for (let x = 30; x < 36; x++) {
        cells[x][16] = <Pixel key = {x.toString()+'16'.toString()} color = {"#A31F34"}/>
        cells[x][17] = <Pixel key = {x.toString()+'17'.toString()} color = {"#A31F34"}/>
      }
      for (let x = 30; x < 32; x++) {
        for (let y = 19; y < 21; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#A31F34"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#A31F34"}/>
        }
      }
      for (let x = 33; x < 36; x++) {
        cells[x][19] = <Pixel key = {x.toString()+'19'.toString()} color = {"#8A8B8C"}/>
        cells[x][20] = <Pixel key = {x.toString()+'20'.toString()} color = {"#8A8B8C"}/>
      }

      for (let y = 22; y < 27; y++) {
        cells[30][y] = <Pixel key = {'30'.toString()+y.toString()} color = {"#A31F34"}/>
        cells[31][y] = <Pixel key = {'31'.toString()+y.toString()} color = {"#A31F34"}/>
      }

      for (let x = 33; x < 36; x++) {
        cells[x][22] = <Pixel key = {x.toString()+'22'.toString()} color = {"#A31F34"}/>
        cells[x][23] = <Pixel key = {x.toString()+'23'.toString()} color = {"#A31F34"}/>
      }
    }
    else if (currShirt === "cmu") {
      for (let x = 30; x < 36; x++) {
        for (let y = 15; y < 17; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 30; x < 36; x++) {
        for (let y = 20; y < 22; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 31; x < 33; x++) {
        cells[x][17] = <Pixel key = {x.toString()+'17'.toString()} color = {"white"}/>
      }
      for (let x = 32; x < 34; x++) {
        cells[x][18] = <Pixel key = {x.toString()+'18'.toString()} color = {"white"}/>
      }
      for (let x = 31; x < 33; x++) {
        cells[x][19] = <Pixel key = {x.toString()+'19'.toString()} color = {"white"}/>
      }
      for (let x = 30; x < 36; x++) {
        for (let y = 23; y < 25; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 30; x < 36; x++) {
        for (let y = 27; y < 29; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 32; x < 36; x++) {
        for (let y = 8; y < 10; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 31; x < 33; x++) {
        for (let y = 9; y < 11; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 30; x < 32; x++) {
        for (let y = 10; y < 13; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 31; x < 33; x++) {
        for (let y = 12; y < 14; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      cells[33][13] = <Pixel key = {'33'.toString()+'13'.toString()} color = {"white"}/>
    } else if (currShirt === "ucla") {
      for (let x = 30; x < 36; x++) {
        for (let y = 8; y < 10; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFD100"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFD100"}/>
        }
      }
      for (let x = 30; x < 36; x++) {
        for (let y = 12; y < 14; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFD100"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFD100"}/>
        }
      }
      for (let x = 32; x < 36; x++) {
        for (let y = 15; y < 17; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFD100"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFD100"}/>
        }
      }
      for (let x = 31; x < 33; x++) {
        for (let y = 18; y < 20; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFD100"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFD100"}/>
        }
      }
      for (let x = 30; x < 32; x++) {
        for (let y = 17; y < 19; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFD100"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFD100"}/>
        }
      }
      cells[31][16] = <Pixel key = {'31'.toString()+'15'.toString()} color = {"#FFD100"}/>
      for (let x = 30; x < 36; x++) {
        for (let y = 21; y < 23; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFD100"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFD100"}/>
        }
      }
      for (let x = 32; x < 36; x++) {
        for (let y = 24; y < 26; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFD100"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFD100"}/>
        }
      }
      for (let x = 32; x < 36; x++) {
        for (let y = 28; y < 30; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFD100"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFD100"}/>
        }
      }
      for (let y = 25; y < 29; y++) {
        cells[31][y] = <Pixel key = {'31'.toString()+y.toString()} color = {"#FFD100"}/>
      }
      for (let y = 26; y < 28; y++) {
        cells[30][y] = <Pixel key = {'30'.toString()+y.toString()} color = {"#FFD100"}/>
      }
      for (let y = 25; y < 29; y++) {
        cells[34][y] = <Pixel key = {'34'.toString()+y.toString()} color = {"#FFD100"}/>
      }
    } else if (currShirt === "uiuc") {
      for (let x = 30; x < 36; x++) {
        for (let y = 15; y < 21; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 29; x < 34; x++) {
        for (let y = 11; y < 25; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 30; x < 36; x++) {
        for (let y = 16; y < 20; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#E84A27"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#E84A27"}/>
        }
      }
      for (let x = 30; x < 33; x++) {
        for (let y = 12; y < 24; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#E84A27"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#E84A27"}/>
        }
      }

    } else if (currShirt === "umich") {
      for (let x = 30; x < 36; x++) {
        for (let y = 14; y < 16; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFCB05"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFCB05"}/>
        }
      }
      for (let x = 30; x < 36; x++) {
        for (let y = 21; y < 23; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFCB05"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFCB05"}/>
        }
      }
      for (let x = 31; x < 33; x++) {
        cells[x][16] = <Pixel key = {x.toString()+'16'.toString()} color = {"#FFCB05"}/>
      }
      for (let x = 32; x < 34; x++) {
        cells[x][17] = <Pixel key = {x.toString()+'17'.toString()} color = {"#FFCB05"}/>
      }
      for (let x = 33; x < 35; x++) {
        cells[x][18] = <Pixel key = {x.toString()+'18'.toString()} color = {"#FFCB05"}/>
      }
      for (let x = 32; x < 34; x++) {
        cells[x][19] = <Pixel key = {x.toString()+'19'.toString()} color = {"#FFCB05"}/>
      }
      for (let x = 31; x < 33; x++) {
        cells[x][20] = <Pixel key = {x.toString()+'20'.toString()} color = {"#FFCB05"}/>
      }
      for (let x = 30; x < 32; x++) {
        cells[x][13] = <Pixel key = {x.toString()+'13'.toString()} color = {"#FFCB05"}/>
      }
      for (let x = 30; x < 32; x++) {
        cells[x][23] = <Pixel key = {x.toString()+'23'.toString()} color = {"#FFCB05"}/>
      }
      for (let y = 13; y < 17; y++) {
        cells[35][y] = <Pixel key = {'35'.toString()+y.toString()} color = {"#FFCB05"}/>
      }
      for (let y = 20; y < 24; y++) {
        cells[35][y] = <Pixel key = {'35'.toString()+y.toString()} color = {"#FFCB05"}/>
      }
    } else if (currShirt === "a&m") {
      for (let x = 30; x < 36; x++) {
        for (let y = 17; y < 19; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 29; x < 31; x++) {
        for (let y = 11; y < 25; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 31; x < 32; x++) {
        for (let y = 11; y < 13; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 31; x < 32; x++) {
        for (let y = 23; y < 25; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 33; x < 36; x++) {
        for (let y = 21; y < 22; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 33; x < 36; x++) {
        for (let y = 26; y < 27; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      cells[34][22] = <Pixel key = {'34'.toString()+'22'.toString()} color = {"white"}/>
      cells[34][25] = <Pixel key = {'34'.toString()+'25'.toString()} color = {"white"}/>
      cells[35][23] = <Pixel key = {'35'.toString()+'23'.toString()} color = {"white"}/>
      cells[35][24] = <Pixel key = {'35'.toString()+'24'.toString()} color = {"white"}/>

      for (let x = 33; x < 36; x++) {
        for (let y = 11-x+33; y < 12-x+33; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 33; x < 36; x++) {
        for (let y = 11+x-33; y < 12+x-33; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let y = 9; y < 13; y++) {
        cells[35][y] = <Pixel key = {'35'.toString()+y.toString()} color = {"white"}/>
        cells[35][y] = <Pixel key = {'35'.toString()+y.toString()} color = {"white"}/>
      }
    } else if (currShirt === "duke") {
      for (let y = 13; y < 21; y++) {
        cells[29][y] = <Pixel key = {'29'.toString()+y.toString()} color = {"white"}/>
      }
      for (let y = 13; y < 19; y++) {
        cells[30][y] = <Pixel key = {'29'.toString()+y.toString()} color = {"white"}/>
      }
      for (let y = 14; y < 17; y++) {
        cells[31][y] = <Pixel key = {'30'.toString()+y.toString()} color = {"white"}/>
      }
      for (let y = 19; y < 22; y++) {
        cells[30][y] = <Pixel key = {'29'.toString()+y.toString()} color = {"white"}/>
      }
      for (let y = 20; y < 23; y++) {
        cells[31][y] = <Pixel key = {'30'.toString()+y.toString()} color = {"white"}/>
      }
      for (let x = 31; x < 36; x++) {
        cells[x][15] = <Pixel key = {x.toString()+'15'.toString()} color = {"white"}/>
        cells[x][16] = <Pixel key = {x.toString()+'16'.toString()} color = {"white"}/>
      }
      for (let x = 31; x < 36; x++) {
        for (let y = 21; y < 23; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
    } else if (currShirt === "uva") {
      for (let y = 13; y < 17; y++) {
        for (let x = 30+y-13; x < 33+y-13; x++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#E57200"}/>
        }
      }
      for (let y = 18; y < 22; y++) {
        for (let x = 33-y+18; x < 36-y+18; x++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#E57200"}/>
        }
      }
      for (let y = 12; y < 16; y++) {
        cells[30][y] = <Pixel key = {'30'.toString()+y.toString()} color = {"#E57200"}/>
      }
      for (let y = 19; y < 23; y++) {
        cells[30][y] = <Pixel key = {'30'.toString()+y.toString()} color = {"#E57200"}/>
      }
      for (let x = 34; x < 36; x++) {
        cells[x][17] = <Pixel key = {x.toString()+'17'.toString()} color = {"#E57200"}/>
      }
    } else if (currShirt === "nyu") {
      for (let x = 30; x < 36; x++) {
        for (let y = 7; y < 9; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 30; x < 36; x++) {
        for (let y = 12; y < 14; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 31; x < 33; x++) {
        cells[x][9] = <Pixel key = {x.toString()+'9'.toString()} color = {"white"}/>
      }
      for (let x = 32; x < 34; x++) {
        cells[x][10] = <Pixel key = {x.toString()+'10'.toString()} color = {"white"}/>
      }
      for (let x = 33; x < 35; x++) {
        cells[x][11] = <Pixel key = {x.toString()+'11 '.toString()} color = {"white"}/>
      }
      for (let x = 30; x < 34; x++) {
        for (let y = 15+x-30; y < 17+x-30; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 30; x < 34; x++) {
        for (let y = 21+30-x; y < 23+30-x; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 34; x < 36; x++) {
        for (let y = 18; y < 20; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 30; x < 36; x++) {
        for (let y = 24; y < 26; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 30; x < 36; x++) {
        for (let y = 28; y < 30; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
    } else if (currShirt === "utaust") {
      for (let x = 34; x < 36; x++) {
        for (let y = 16; y < 20; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 31; x < 34; x++) {
        for (let y = 15; y < 21; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 29; x < 30; x++) {
        for (let y = 16; y < 20; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 30; x < 31; x++) {
        for (let y = 12; y < 24; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 29; x < 30; x++) {
        for (let y = 7; y < 14; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 29; x < 30; x++) {
        for (let y = 22; y < 30; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 31; x < 32; x++) {
        for (let y = 14; y < 15; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
      for (let x = 31; x < 32; x++) {
        for (let y = 21; y < 22; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
        }
      }
    } else if (currShirt === "usc") {
      //S
      for (let x = 32; x < 35; x++) {
        for (let y = 15; y < 17; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFCC00"}/>
        }
      }
      for (let x = 31; x < 33; x++) {
        for (let y = 16; y < 18; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFCC00"}/>
        }
      }
      for (let x = 30; x < 32; x++) {
        for (let y = 17; y < 20; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFCC00"}/>
        }
      }
      for (let x = 31; x < 33; x++) {
        for (let y = 19; y < 21; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFCC00"}/>
        }
      }
      for (let x = 35; x < 36; x++) {
        for (let y = 16; y < 21; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFCC00"}/>
        }
      }
      cells[34][17] = <Pixel key = {'34'.toString()+'17'.toString()} color = {"#FFCC00"}/>
      //U
      for (let x = 30; x < 36; x++) {
        for (let y = 7; y < 9; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFCC00"}/>
        }
      }
      for (let x = 30; x < 36; x++) {
        for (let y = 11; y < 13; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFCC00"}/>
        }
      }
      //C
      for (let x = 32; x < 36; x++) {
        for (let y = 23; y < 25; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFCC00"}/>
        }
      }
      for (let x = 31; x < 33; x++) {
        for (let y = 24; y < 26; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFCC00"}/>
        }
      }
      for (let x = 30; x < 32; x++) {
        for (let y = 25; y < 30; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFCC00"}/>
        }
      }
      for (let x = 32; x < 33; x++) {
        for (let y = 28; y < 30; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#FFCC00"}/>
        }
      }
    }
    cells = <LoadEyes cells = {cells}/>
  return (
      cells
  );
}

export function LoadEyes(props) {
  var cells = props.cells
  const {currEyeStyle, currEyeColor} = useDesign()
  var eyes = currEyeStyle
  if (eyes === "right") {
    cells[15][24] = <Pixel key = {'15'.toString()+'24'.toString()} color = {`#${currEyeColor}`}/>
    cells[15][23] = <Pixel key = {'15'.toString()+'23'.toString()} color = {"white"}/>

    cells[15][19] = <Pixel key = {'15'.toString()+'19'.toString()} color = {`#${currEyeColor}`}/>
    cells[15][18] = <Pixel key = {'15'.toString()+'18'.toString()} color = {"white"}/>
  }

  if (eyes === "right-eyebags") {
    cells[15][24] = <Pixel key = {'15'.toString()+'24'.toString()} color = {`#${currEyeColor}`}/>
    cells[15][23] = <Pixel key = {'15'.toString()+'23'.toString()} color = {"white"}/>

    cells[15][19] = <Pixel key = {'15'.toString()+'19'.toString()} color = {`#${currEyeColor}`}/>
    cells[15][18] = <Pixel key = {'15'.toString()+'18'.toString()} color = {"white"}/>

    cells[16][24] = <Pixel key = {'16'.toString()+'24'.toString()} color = {"#52527a"}/>
    cells[16][23] = <Pixel key = {'16'.toString()+'23'.toString()} color = {"#52527a"}/>

    cells[16][19] = <Pixel key = {'16'.toString()+'19'.toString()} color = {"#52527a"}/>
    cells[16][18] = <Pixel key = {'16'.toString()+'18'.toString()} color = {"#52527a"}/>
  }

  if (eyes === "left") {
    cells[15][24] = <Pixel key = {'15'.toString()+'24'.toString()} color = {"white"}/>
    cells[15][23] = <Pixel key = {'15'.toString()+'23'.toString()} color = {`#${currEyeColor}`}/>

    cells[15][19] = <Pixel key = {'15'.toString()+'19'.toString()} color = {"white"}/>
    cells[15][18] = <Pixel key = {'15'.toString()+'18'.toString()} color = {`#${currEyeColor}`}/>
  }

  if (eyes === "left-eyebags") {
    cells[15][24] = <Pixel key = {'15'.toString()+'24'.toString()} color = {"white"}/>
    cells[15][23] = <Pixel key = {'15'.toString()+'23'.toString()} color = {`#${currEyeColor}`}/>

    cells[15][19] = <Pixel key = {'15'.toString()+'19'.toString()} color = {"white"}/>
    cells[15][18] = <Pixel key = {'15'.toString()+'18'.toString()} color = {`#${currEyeColor}`}/>

    cells[16][24] = <Pixel key = {'16'.toString()+'24'.toString()} color = {"#52527a"}/>
    cells[16][23] = <Pixel key = {'16'.toString()+'23'.toString()} color = {"#52527a"}/>

    cells[16][19] = <Pixel key = {'16'.toString()+'19'.toString()} color = {"#52527a"}/>
    cells[16][18] = <Pixel key = {'16'.toString()+'18'.toString()} color = {"#52527a"}/>
  }

  if (eyes === "cyclops") {
    cells[15][22] = <Pixel key = {'15'.toString()+'22'.toString()} color = {"white"}/>
    cells[15][21] = <Pixel key = {'15'.toString()+'21'.toString()} color = {`#${currEyeColor}`}/>
    cells[15][20] = <Pixel key = {'15'.toString()+'20'.toString()} color = {"white"}/>
  }

  if (eyes === "glasses-red") {
    for (let x = 14; x < 17; x++) {
      for (let y = 22; y < 26; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#bf4040"}/>
      }
    }
    for (let x = 14; x < 17; x++) {
      for (let y = 17; y < 21; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#bf4040"}/>
      }
    }
    cells[14][21] = <Pixel key = {'14'.toString()+'21'.toString()} color = {"#bf4040"}/>
    cells[14][16] = <Pixel key = {'14'.toString()+'16'.toString()} color = {"#bf4040"}/>

    //eyes
    cells[15][24] = <Pixel key = {'15'.toString()+'24'.toString()} color = {`#${currEyeColor}`}/>
    cells[15][23] = <Pixel key = {'15'.toString()+'23'.toString()} color = {"white"}/>

    cells[15][19] = <Pixel key = {'15'.toString()+'19'.toString()} color = {`#${currEyeColor}`}/>
    cells[15][18] = <Pixel key = {'15'.toString()+'18'.toString()} color = {"white"}/>

  }
  if (eyes === "glasses-brown") {
    for (let x = 14; x < 17; x++) {
      for (let y = 22; y < 26; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#8B4513"}/>
      }
    }
    for (let x = 14; x < 17; x++) {
      for (let y = 17; y < 21; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#8B4513"}/>
      }
    }
    cells[14][21] = <Pixel key = {'14'.toString()+'21'.toString()} color = {"#bf4040"}/>
    cells[14][16] = <Pixel key = {'14'.toString()+'16'.toString()} color = {"#bf4040"}/>

    //eyes
    cells[15][24] = <Pixel key = {'15'.toString()+'24'.toString()} color = {`#${currEyeColor}`}/>
    cells[15][23] = <Pixel key = {'15'.toString()+'23'.toString()} color = {"white"}/>

    cells[15][19] = <Pixel key = {'15'.toString()+'19'.toString()} color = {`#${currEyeColor}`}/>
    cells[15][18] = <Pixel key = {'15'.toString()+'18'.toString()} color = {"white"}/>
  }

  if (eyes === "sunglasses") {
    for (let y = 15; y < 26; y++) {
      cells[14][y] = <Pixel key = {'14'.toString()+y.toString()} color = {"#262626"}/>
    }
    for (let y = 18; y < 21; y++) {
      cells[15][y] = <Pixel key = {'15'.toString()+y.toString()} color = {"#262626"}/>
    }

    for (let y = 23; y < 26; y++) {
      cells[15][y] = <Pixel key = {'15'.toString()+y.toString()} color = {"#262626"}/>
    }
  }
  if (eyes === "eyepatch") {
    cells[15][24] = <Pixel key = {'15'.toString()+'24'.toString()} color = {`#${currEyeColor}`}/>
    cells[15][23] = <Pixel key = {'15'.toString()+'23'.toString()} color = {"white"}/>
      for (let y = 15; y < 26; y++) {
        cells[14][y] = <Pixel key = {'14'.toString()+y.toString()} color = {"#262626"}/>
      }
      for (let y = 17; y < 20; y++) {
        cells[15][y] = <Pixel key = {'15'.toString()+y.toString()} color = {"#262626"}/>
      }
      for (let y = 17; y < 20; y++) {
        cells[16][y] = <Pixel key = {'16'.toString()+y.toString()} color = {"#262626"}/>
      }
    }
    if (eyes === "laserbeam") {
      for (let x = 14; x < 17; x++) {
        for (let y = 17; y < 26; y++) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#cccccc"}/>
        }
      }
      for (let y = 18; y < 25; y++) {
        cells[15][y] = <Pixel key = {'15'.toString()+y.toString()} color = {"#000000"}/>
      }
      cells[14][17] = <Pixel key = {'14'.toString()+'17'.toString()} color = {"#8c8c8c"}/>
      cells[16][17] = <Pixel key = {'16'.toString()+'17'.toString()} color = {"#8c8c8c"}/>
      cells[14][25] = <Pixel key = {'14'.toString()+'25'.toString()} color = {"#8c8c8c"}/>
      cells[16][25] = <Pixel key = {'16'.toString()+'25'.toString()} color = {"#8c8c8c"}/>
    }
    cells = <LoadNose cells = {cells}/>
  return (
      cells
  );
}

export function LoadNose(props) {
  const {currNose} = useDesign()
  var cells = props.cells
  var nose = currNose
  if (nose === "dot") {
    cells[17][21] = <Pixel key = {'17'.toString()+'21'.toString()} color = {"black"}/>
  }
  if (nose === "v-line") {
    cells[17][21] = <Pixel key = {'17'.toString()+'21'.toString()} color = {"black"}/>
    cells[18][21] = <Pixel key = {'18'.toString()+'21'.toString()} color = {"black"}/>
  }
  if (nose === "h-line") {
    cells[17][21] = <Pixel key = {'17'.toString()+'21'.toString()} color = {"black"}/>
    cells[17][22] = <Pixel key = {'17'.toString()+'22'.toString()} color = {"black"}/>
  }
  if (nose === "nostril") {
    cells[17][20] = <Pixel key = {'17'.toString()+'20'.toString()} color = {"black"}/>
    cells[17][22] = <Pixel key = {'17'.toString()+'22'.toString()} color = {"black"}/>
  }
  if (nose === "clown") {
    for (let x = 17; x < 19; x++) {
      for (let y = 21; y < 23; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#ff3333"}/>
      }
    }
  }
  cells = <LoadHair cells = {cells}/>
  return (
      cells
  );
}

export function LoadHair(props){
    var cells = props.cells;
    const {currHairStyle, currHairColor} = useDesign()
    var hair = currHairStyle;
    var color = `#${currHairColor}`;
    if (hair === "baldhead") {

    } else {
    for (let x = 9; x < 12; x++) {
      for (let y = 17; y < 24; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {color}/>
      }
    }

    for (let x = 10; x < 13; x++) {
      cells[x][16] = <Pixel key = {x.toString()+'16'.toString()} color = {color}/>
      cells[x][24] = <Pixel key = {x.toString()+'24'.toString()} color = {color}/>
    }

    for (let x = 11; x < 14; x++) {
      cells[x][15] = <Pixel key = {x.toString()+'15'.toString()} color = {color}/>
      cells[x][25] = <Pixel key = {x.toString()+'25'.toString()} color = {color}/>
    }
  }

  if (hair === "tall-afro") {
    for (let x = 5; x < 11; x++) {
      for (let y = 15; y < 26; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let y = 16; y < 25; y++) {
      cells[4][y] = <Pixel key = {'4'.toString()+y.toString()} color = {`#${currHairColor}`}/>
    }
  }
  if (hair === "mohawk") {
    for (let x = 7; x < 11; x++) {
      for (let y = 17; y < 23; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 4; x < 10; x++) {
      for (let y = 19-x+6; y < 23; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
  }
  if (hair === "afro") {
    for (let x = 6; x < 12; x++) {
      for (let y = 13; y < 27; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 12; x < 14; x++) {
        cells[x][14] = <Pixel key = {x.toString()+'14'.toString()} color = {`#${currHairColor}`}/>
    }

    for (let x = 3; x < 6; x++) {
      for (let y = 13-x+5; y < 24+x-3; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }

    for (let x = 7; x < 10; x++) {
        cells[x][12] = <Pixel key = {x.toString()+'12'.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 7; x < 11; x++) {
        cells[x][27] = <Pixel key = {x.toString()+'27'.toString()} color = {`#${currHairColor}`}/>
    }
    for (let y = 19; y < 23; y++) {
        cells[2][y] = <Pixel key = {'2'.toString()+y.toString()} color = {`#${currHairColor}`}/>
    }
    for (let y = 12; y < 14; y++) {
        cells[5][y] = <Pixel key = {'5'.toString()+y.toString()} color = {`#${currHairColor}`}/>
    }
    cells[2][16] = <Pixel key = {'2'.toString()+'16'.toString()} color = {`#${currHairColor}`}/>
  }
  if (hair === "1-curl") {
    for (let x = 7; x < 11; x++) {
      for (let y = 22-x+9; y < 27; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
  }
  if (hair === "middlepart") {
    for (let x = 12; x < 15; x++) {
      for (let y = 13-x+13; y < 16; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
      for (let y = 26; y < 28+x-13; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
  }
  if (hair === "bun") {
    for (let x = 5; x < 8; x++) {
      for (let y = 16; y < 20; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let y = 17; y < 20; y++) {
      if (`#${currHairColor}` === "black" | `#${currHairColor}` === "#000000") {
        cells[8][y] = <Pixel key = {'8'.toString()+y.toString()} color = {"white"}/>
      } else {
        cells[8][y] = <Pixel key = {'8'.toString()+y.toString()} color = {"black"}/>
      }
    }
  }
  if (hair === "high-ponytail-1") {
    for (let x = 5; x < 8; x++) {
      for (let y = 16; y < 20; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let y = 17; y < 20; y++) {
      if (`#${currHairColor}` === "black" | `#${currHairColor}` === "#000000") {
        cells[8][y] = <Pixel key = {'8'.toString()+y.toString()} color = {"white"}/>
      } else {
        cells[8][y] = <Pixel key = {'8'.toString()+y.toString()} color = {"black"}/>
      }
    }
    for (let x = 6; x < 10; x++) {
      for (let y = 13-x+8; y < 16-x+8; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 10; x < 13; x++) {
      for (let y = 11; y < 13; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 13; x < 15; x++) {
      for (let y = 12; y < 14; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 15; x < 18; x++) {
      for (let y = 11; y < 13; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 18; x < 20; x++) {
      for (let y = 12; y < 14; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 20; x < 22; x++) {
      for (let y = 13; y < 15; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 22; x < 25; x++) {
      for (let y = 13; y < 14; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
  }
  if (hair === "high-ponytail-2") {
    for (let x = 5; x < 8; x++) {
      for (let y = 16; y < 20; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let y = 17; y < 20; y++) {
      if (`#${currHairColor}` === "black" | `#${currHairColor}` === "#000000") {
        cells[8][y] = <Pixel key = {'8'.toString()+y.toString()} color = {"white"}/>
      } else {
        cells[8][y] = <Pixel key = {'8'.toString()+y.toString()} color = {"black"}/>
      }
    }
    for (let x = 5; x < 8; x++) {
      for (let y = 14; y < 20; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let y = 15; y < 19; y++) {
      cells[4][y] = <Pixel key = {'4'.toString()+y.toString()} color = {`#${currHairColor}`}/>
    }

    for (let x = 6; x < 10; x++) {
      for (let y = 13; y < 15; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 8; x < 11; x++) {
      for (let y = 12; y < 14; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 10; x < 16; x++) {
      for (let y = 11; y < 13; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 13; x < 21; x++) {
      for (let y = 10; y < 12; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 21; x < 23; x++) {
      cells[x][10] = <Pixel key = {x.toString()+'10'.toString()} color = {`#${currHairColor}`}/>
    }
  }
  if (hair === "right-longhair") {
    for (let x = 13; x < 22; x++) {
      for (let y = 26; y < 27; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let y = 25; y < 27; y++) {
      cells[22][y] = <Pixel key = {'22'.toString()+y.toString()} color = {`#${currHairColor}`}/>
    }
    for (let y = 24; y < 27; y++) {
      cells[23][y] = <Pixel key = {'23'.toString()+y.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 24; x < 28; x++) {
      for (let y = 21; y < 27; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
  }
  if (hair === "left-longhair") {
    for (let x = 12; x < 15; x++) {
      for (let y = 14; y < 16; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 13; x < 18; x++) {
      for (let y = 13; y < 14; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 18; x < 28; x++) {
      for (let y = 12; y < 15; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
  }
  if (hair === "longhair") {
    for (let x = 12; x < 15; x++) {
      for (let y = 14; y < 16; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 13; x < 18; x++) {
      for (let y = 13; y < 14; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 18; x < 28; x++) {
      for (let y = 12; y < 15; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 13; x < 22; x++) {
      for (let y = 26; y < 27; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let y = 25; y < 27; y++) {
      cells[22][y] = <Pixel key = {'22'.toString()+y.toString()} color = {`#${currHairColor}`}/>
    }
    for (let y = 24; y < 27; y++) {
      cells[23][y] = <Pixel key = {'23'.toString()+y.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 24; x < 28; x++) {
      for (let y = 21; y < 27; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
  }
  if (hair === "shoulder-longhair") {
    for (let x = 12; x < 15; x++) {
      for (let y = 14; y < 16; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 13; x < 18; x++) {
      for (let y = 13; y < 14; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 18; x < 28; x++) {
      for (let y = 12; y < 15; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 13; x < 22; x++) {
      for (let y = 26; y < 27; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let y = 25; y < 27; y++) {
      cells[22][y] = <Pixel key = {'22'.toString()+y.toString()} color = {`#${currHairColor}`}/>
    }
    for (let y = 24; y < 27; y++) {
      cells[23][y] = <Pixel key = {'23'.toString()+y.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 24; x < 28; x++) {
      for (let y = 21; y < 27; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 27; x < 31; x++) {
      for (let y = 11; y < 14; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 31; x < 33; x++) {
      for (let y = 11; y < 14-x+30; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 26; x < 29; x++) {
      for (let y = 21; y < 27; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 29; x < 33; x++) {
      for (let y = 23+x-29; y < 27; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
  }
  if (hair === "wavy-longhair") {
    for (let x = 18; x < 20; x++) {
      cells[x][14] = <Pixel key = {x.toString()+'14'.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 15; x < 31; x++) {
      for (let y = 11; y < 14; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 17; x < 21; x++) {
      cells[x][10] = <Pixel key = {x.toString()+'10'.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 13; x < 15; x++) {
      cells[x][12] = <Pixel key = {x.toString()+'12'.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 11; x < 13; x++) {
      cells[x][13] = <Pixel key = {x.toString()+'13'.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 25; x < 29; x++) {
      cells[x][10] = <Pixel key = {x.toString()+'10'.toString()} color = {`#${currHairColor}`}/>
    }
    for (let y = 15; y < 17; y++) {
      cells[9][y] = <Pixel key = {'9'.toString()+y.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 31; x < 34; x++) {
      for (let y = 10; y < 12; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 10; x < 12; x++) {
      for (let y = 14; y < 16; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }

    //right side of hairtype
    for (let x = 23; x < 31; x++) {
      for (let y = 24; y < 27; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 15; x < 20; x++) {
      cells[x][27] = <Pixel key = {x.toString()+'27'.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 25; x < 29; x++) {
      cells[x][27] = <Pixel key = {x.toString()+'27'.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 24; x < 26; x++) {
      cells[x][23] = <Pixel key = {x.toString()+'23'.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 30; x < 32; x++) {
      cells[x][23] = <Pixel key = {x.toString()+'23'.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 30; x < 36; x++) {
      for (let y = 23-x+32; y < 25-x+32; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    cells[34][11] = <Pixel key = {'34'.toString()+'11'.toString()} color = {`#${currHairColor}`}/>
    cells[35][12] = <Pixel key = {'35'.toString()+'12'.toString()} color = {`#${currHairColor}`}/>
    for (let x = 12; x < 15; x++) {
      for (let y = 14; y < 16; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 13; x < 18; x++) {
      for (let y = 13; y < 14; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 18; x < 24; x++) {
      for (let y = 12; y < 15; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 13; x < 22; x++) {
      for (let y = 26; y < 27; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let y = 25; y < 27; y++) {
      cells[22][y] = <Pixel key = {'22'.toString()+y.toString()} color = {`#${currHairColor}`}/>
    }
    for (let y = 24; y < 27; y++) {
      cells[23][y] = <Pixel key = {'23'.toString()+y.toString()} color = {`#${currHairColor}`}/>
    }
  }
  if (hair === "shorthair") {
    for (let x = 12; x < 15; x++) {
      for (let y = 14; y < 16; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 13; x < 18; x++) {
      for (let y = 13; y < 14; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 18; x < 24; x++) {
      for (let y = 12; y < 15; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 13; x < 22; x++) {
      for (let y = 26; y < 27; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let y = 25; y < 27; y++) {
      cells[22][y] = <Pixel key = {'22'.toString()+y.toString()} color = {`#${currHairColor}`}/>
    }
    for (let y = 24; y < 27; y++) {
      cells[23][y] = <Pixel key = {'23'.toString()+y.toString()} color = {`#${currHairColor}`}/>
    }
  }
  if (hair === "wavy-shorthair") {
    for (let x = 14; x < 22; x++) {
      for (let y = 26; y < 28; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 18; x < 22; x++) {
      cells[x][28] = <Pixel key = {x.toString()+'28'.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 15; x < 22; x++) {
      for (let y = 11; y < 14; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 13; x < 16; x++) {
      for (let y = 12; y < 15; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 11; x < 14; x++) {
      for (let y = 13; y < 17; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 10; x < 13; x++) {
      for (let y = 14; y < 18; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let y = 15; y < 18; y++) {
      cells[9][y] = <Pixel key = {'9'.toString()+y.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 17; x < 23; x++) {
      cells[x][10] = <Pixel key = {x.toString()+'10'.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 19; x < 22; x++) {
      cells[x][9] = <Pixel key = {x.toString()+'9'.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 12; x < 14; x++) {
      cells[x][26] = <Pixel key = {x.toString()+'26'.toString()} color = {`#${currHairColor}`}/>
    }
    for (var x = 0; x < wavyShortHairPixels.length; x++) {
      cells[wavyShortHairPixels[x][0]][wavyShortHairPixels[x][1]] = <Pixel key = {wavyShortHairPixels[x][0].toString()+wavyShortHairPixels[x][1].toString()} color = {`#${currHairColor}`}/>
    }
  }
  if (hair === "4-curl") {
    for (let x = 8; x < 12; x++) {
      for (let y = 13; y < 27; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 12; x < 14; x++) {
        cells[x][14] = <Pixel key = {x.toString()+'14'.toString()} color = {`#${currHairColor}`}/>
    }

    for (let x = 5; x < 9; x++) {
      for (let y = 13-x+7; y < 24+x-10; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
      for (let y = 19-x+7; y < 24+x-4; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }

    for (let x = 9; x < 18; x++) {
      for (let y = 12; y < 14; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 9; x < 17; x++) {
      cells[x][11] = <Pixel key = {x.toString()+'11'.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 11; x < 16; x++) {
      cells[x][10] = <Pixel key = {x.toString()+'10'.toString()} color = {`#${currHairColor}`}/>
    }
    for (let y = 13; y < 15; y++) {
      cells[18][y] = <Pixel key = {'18'.toString()+y.toString()} color = {`#${currHairColor}`}/>
    }
    for (let y = 13; y < 15; y++) {
      cells[18][y] = <Pixel key = {'18'.toString()+y.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 8; x < 18; x++) {
      for (let y = 26; y < 28; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 9; x < 17; x++) {
      cells[x][28] = <Pixel key = {x.toString()+'28'.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 10; x < 16; x++) {
      cells[x][29] = <Pixel key = {x.toString()+'29'.toString()} color = {`#${currHairColor}`}/>
    }
    for (var x = 0; x < curl4Pixels.length; x++) {
      cells[curl4Pixels[x][0]][curl4Pixels[x][1]] = <Pixel key = {curl4Pixels[x][0].toString()+curl4Pixels[x][1].toString()} color = {`#${currHairColor}`}/>
    }
  }
  if (hair === "4-curl-2") {
    for (let x = 8; x < 12; x++) {
      for (let y = 13; y < 27; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 12; x < 14; x++) {
        cells[x][14] = <Pixel key = {x.toString()+'14'.toString()} color = {`#${currHairColor}`}/>
    }

    for (let x = 5; x < 9; x++) {
      for (let y = 13-x+7; y < 24+x-10; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
      for (let y = 19-x+7; y < 24+x-4; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }

    for (let x = 9; x < 18; x++) {
      for (let y = 12; y < 14; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 9; x < 17; x++) {
      cells[x][11] = <Pixel key = {x.toString()+'11'.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 11; x < 16; x++) {
      cells[x][10] = <Pixel key = {x.toString()+'10'.toString()} color = {`#${currHairColor}`}/>
    }
    for (let y = 13; y < 15; y++) {
      cells[18][y] = <Pixel key = {'18'.toString()+y.toString()} color = {`#${currHairColor}`}/>
    }
    for (let y = 13; y < 15; y++) {
      cells[18][y] = <Pixel key = {'18'.toString()+y.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 8; x < 18; x++) {
      for (let y = 26; y < 28; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 9; x < 17; x++) {
      cells[x][28] = <Pixel key = {x.toString()+'28'.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 10; x < 16; x++) {
      cells[x][29] = <Pixel key = {x.toString()+'29'.toString()} color = {`#${currHairColor}`}/>
    }
    for (var x = 0; x < curl4Pixels.length; x++) {
      cells[curl4Pixels[x][0]][curl4Pixels[x][1]] = <Pixel key = {curl4Pixels[x][0].toString()+curl4Pixels[x][1].toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 18; x < 25; x++) {
      cells[x][14] = <Pixel key = {x.toString()+'14'.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 16; x < 24; x++) {
      cells[x][12] = <Pixel key = {x.toString()+'12'.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 14; x < 21; x++) {
      cells[x][10] = <Pixel key = {x.toString()+'10'.toString()} color = {`#${currHairColor}`}/>
    }
    for (var x = 0; x < curl4Pixels2.length; x++) {
      cells[curl4Pixels2[x][0]][curl4Pixels2[x][1]] = <Pixel key = {curl4Pixels2[x][0].toString()+curl4Pixels2[x][1].toString()} color = {`#${currHairColor}`}/>
    }
  }
  if (hair === "1-curl-2") {
    for (var x = 0; x < curl1Pixels2.length; x++) {
      cells[curl1Pixels2[x][0]][curl1Pixels2[x][1]] = <Pixel key = {curl1Pixels2[x][0].toString()+curl1Pixels2[x][1].toString()} color = {`#${currHairColor}`}/>
    }
  }
  if (hair === "3-curl") {
    for (let x = 8; x < 13; x++) {
      for (let y = 13; y < 27; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 6; x < 8; x++) {
      for (let y = 15; y < 24; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (var x = 0; x < curl3Pixels.length; x++) {
      cells[curl3Pixels[x][0]][curl3Pixels[x][1]] = <Pixel key = {curl3Pixels[x][0].toString()+curl3Pixels[x][1].toString()} color = {`#${currHairColor}`}/>
    }
  }
  if (hair === "2-curl") {
    for (let x = 9; x < 12; x++) {
      for (let y = 15; y < 25; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let x = 10; x < 13; x++) {
      for (let y = 25; y < 27; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (let y = 17; y < 22; y++) {
      cells[8][y] = <Pixel key = {'8'.toString()+y.toString()} color = {`#${currHairColor}`}/>
    }
    for (let x = 10; x < 14; x++) {
      cells[x][14] = <Pixel key = {x.toString()+'14'.toString()} color = {`#${currHairColor}`}/>
    }
    for (var x = 0; x < curl2Pixels.length; x++) {
      cells[curl2Pixels[x][0]][curl2Pixels[x][1]] = <Pixel key = {curl2Pixels[x][0].toString()+curl2Pixels[x][1].toString()} color = {`#${currHairColor}`}/>
    }
  }
  if (hair === "sidepart") {
    for (let x = 10; x < 12; x++) {
      for (let y = 16; y < 27; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#${currHairColor}`}/>
      }
    }
    for (var x = 0; x < sidePartPixels.length; x++) {
      cells[sidePartPixels[x][0]][sidePartPixels[x][1]] = <Pixel key = {sidePartPixels[x][0].toString()+sidePartPixels[x][1].toString()} color = {`#${currHairColor}`}/>
    }
  }
  cells = <LoadMouth cells = {cells}/>
  return (
      cells
  );
}


export function LoadMouth(props) {
  var cells = props.cells
  const {currMouth, currHairColor} = useDesign()
  var mouth = currMouth
  if (mouth === "line-long") {
    for (let y = 19; y < 24; y++) {
      cells[20][y] = <Pixel key = {'20'.toString()+y.toString()} color = {"black"}/>
    }
  }
  if (mouth === "line-short") {
    for (let y = 20; y < 23; y++) {
      cells[20][y] = <Pixel key = {'20'.toString()+y.toString()} color = {"black"}/>
    }
  }
  if (mouth === "smirk") {
    for (let y = 20; y < 23; y++) {
      cells[20][y] = <Pixel key = {'20'.toString()+y.toString()} color = {"black"}/>
    }
    cells[19][19] = <Pixel key = {'29'.toString()+'19'.toString()} color = {"black"}/>
  }
  if (mouth === "stache") {
    for (let y = 20; y < 23; y++) {
      cells[20][y] = <Pixel key = {'20'.toString()+y.toString()} color = {"black"}/>
    }
    for (let y = 19; y < 24; y++) {
      cells[19][y] = <Pixel key = {'19'.toString()+y.toString()} color = {`#${currHairColor}`}/>
    }
    }
  if (mouth === "tongue") {
    for (let y = 20; y < 24; y++) {
      cells[20][y] = <Pixel key = {'20'.toString()+y.toString()} color = {"black"}/>
    }
    cells[21][21] = <Pixel key = {'21'.toString()+'21'.toString()} color = {"pink"}/>
    cells[21][22] = <Pixel key = {'21'.toString()+'22'.toString()} color = {"pink"}/>
  }
  if (mouth === "tongue-2") {
    for (let y = 20; y < 24; y++) {
      cells[20][y] = <Pixel key = {'20'.toString()+y.toString()} color = {"black"}/>
    }
    cells[21][22] = <Pixel key = {'21'.toString()+'22'.toString()} color = {"pink"}/>
    cells[21][23] = <Pixel key = {'21'.toString()+'23'.toString()} color = {"pink"}/>
  }
  if (mouth === "vampire") {
    for (let y = 20; y < 24; y++) {
      cells[20][y] = <Pixel key = {'20'.toString()+y.toString()} color = {"black"}/>
    }
    cells[21][20] = <Pixel key = {'21'.toString()+'20'.toString()} color = {"white"}/>
    cells[21][23] = <Pixel key = {'21'.toString()+'23'.toString()} color = {"white"}/>
  }
  if (mouth === "olips") {
    for (let y = 21; y < 24; y++) {
      cells[19][y] = <Pixel key = {'19'.toString()+y.toString()} color = {"#C98276"}/>
    }
    for (let y = 21; y < 24; y++) {
      cells[21][y] = <Pixel key = {'21'.toString()+y.toString()} color = {"#C98276"}/>
    }
    cells[20][21] = <Pixel key = {'29'.toString()+'19'.toString()} color = {"#C98276"}/>
    cells[20][23] = <Pixel key = {'29'.toString()+'19'.toString()} color = {"#C98276"}/>
  }
  if (mouth === "olips-2") {
    for (let y = 21; y < 24; y++) {
      cells[19][y] = <Pixel key = {'19'.toString()+y.toString()} color = {"black"}/>
    }
    for (let y = 21; y < 24; y++) {
      cells[21][y] = <Pixel key = {'21'.toString()+y.toString()} color = {"black"}/>
    }
    cells[20][21] = <Pixel key = {'29'.toString()+'19'.toString()} color = {"black"}/>
    cells[20][23] = <Pixel key = {'29'.toString()+'19'.toString()} color = {"black"}/>
  }
  if (mouth === "purple-lipgloss") {
    for (let y = 20; y < 24; y++) {
      cells[20][y] = <Pixel key = {'20'.toString()+y.toString()} color = {"#cc0099"}/>
    }
  }
  if (mouth === "green-lipgloss") {
    for (let y = 20; y < 24; y++) {
      cells[20][y] = <Pixel key = {'20'.toString()+y.toString()} color = {"green"}/>
    }
  }
  if (mouth === "blue-lipgloss") {
    for (let y = 20; y < 24; y++) {
      cells[20][y] = <Pixel key = {'20'.toString()+y.toString()} color = {"blue"}/>
    }
  }
  if (mouth === "joint") {
    for (let y = 20; y < 24; y++) {
      cells[20][y] = <Pixel key = {'20'.toString()+y.toString()} color = {"black"}/>
    }
    for (let y = 23; y < 27; y++) {
      cells[20][y] = <Pixel key = {'20'.toString()+y.toString()} color = {"#efebe9"}/>
    }
    cells[20][27] = <Pixel key = {'20'.toString()+'27'.toString()} color = {"#d84315"}/>
    cells[20][28] = <Pixel key = {'20'.toString()+'28'.toString()} color = {"black"}/>
    for (let y = 23; y < 28; y++) {
      cells[19][y] = <Pixel key = {'19'.toString()+y.toString()} color = {"black"}/>
    }
    for (let y = 23; y < 28; y++) {
      cells[21][y] = <Pixel key = {'21'.toString()+y.toString()} color = {"black"}/>
    }

    for (let x = 13; x < 19; x++) {
      cells[x][28] = <Pixel key = {x.toString()+'28'.toString()} color = {"grey"}/>
    }
  }
  if (mouth === "pipe") {
    for (let y = 20; y < 24; y++) {
      cells[20][y] = <Pixel key = {'20'.toString()+y.toString()} color = {"#993366"}/>
    }
    for (let x = 21; x < 25; x++) {
      for (let y = x+2; y < x+3; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#996600`}/>
      }
    }
    for (let x = 20; x < 24; x++) {
      for (let y = x+3; y < x+4; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`black`}/>
      }
    }
    for (let x = 21; x < 26; x++) {
      for (let y = x+1; y < x+2; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`black`}/>
      }
    }
    for (let y = 26; y < 30; y++) {
      cells[25][y] = <Pixel key = {'25'.toString()+y.toString()} color = {`black`}/>
    }
    for (let y = 27; y < 32; y++) {
      cells[21][y] = <Pixel key = {'21'.toString()+y.toString()} color = {`black`}/>
    }
    for (let x = 21; x < 24; x++) {
      cells[x][27] = <Pixel key = {x.toString()+'27'.toString()} color = {`black`}/>
    }
    for (let x = 21; x < 24; x++) {
      cells[x][31] = <Pixel key = {x.toString()+'31'.toString()} color = {`black`}/>
    }
    for (let y = 26; y < 30; y++) {
      cells[24][y] = <Pixel key = {'24'.toString()+y.toString()} color = {`#996600`}/>
    }
    cells[24][30] = <Pixel key = {'24'.toString()+'30'.toString()} color = {`black`}/>
    for (let y = 28; y < 31; y++) {
      cells[22][y] = <Pixel key = {'22'.toString()+y.toString()} color = {`#996600`}/>
    }
    cells[23][29] = <Pixel key = {'23'.toString()+'29'.toString()} color = {`#996600`}/>
    cells[23][28] = <Pixel key = {'23'.toString()+'28'.toString()} color = {`#805500`}/>
    cells[23][30] = <Pixel key = {'23'.toString()+'30'.toString()} color = {`#805500`}/>
    cells[24][29] = <Pixel key = {'24'.toString()+'29'.toString()} color = {`#805500`}/>
    //smoke
    cells[19][29] = <Pixel key = {'19'.toString()+'29'.toString()} color = {`#b3b3b3`}/>
    cells[17][29] = <Pixel key = {'17'.toString()+'29'.toString()} color = {`#b3b3b3`}/>
    for (let x = 14; x < 16; x++) {
      for (let y = 28; y < 31; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {`#b3b3b3`}/>
      }
    }
  }
  if (mouth === "facemask1") {
    for (let x = 18; x < 22; x++) {
      for (let y = 19; y < 24; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#bbdefb"}/>
      }
    }
    for (let x = 15; x < 19; x++) {
      cells[x][x] = <Pixel key = {x.toString()+x.toString()} color = {"#bbdefb"}/>
    }
    for (let x = 16; x < 19; x++) {
      cells[x+3][x] = <Pixel key = {(x+3).toString()+x.toString()} color = {"#bbdefb"}/>
    }
    cells[21][24] = <Pixel key = {'21'.toString()+'24'.toString()} color = {"#bbdefb"}/>
    cells[17][24] = <Pixel key = {'17'.toString()+'24'.toString()} color = {"#bbdefb"}/>
  }
  if (mouth === "facemask2") {
    for (let x = 18; x < 22; x++) {
      for (let y = 19; y < 24; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#303030"}/>
      }
    }
    for (let x = 15; x < 19; x++) {
      cells[x][x] = <Pixel key = {x.toString()+x.toString()} color = {"#303030"}/>
    }
    for (let x = 16; x < 19; x++) {
      cells[x+3][x] = <Pixel key = {(x+3).toString()+x.toString()} color = {"#303030"}/>
    }
    cells[21][24] = <Pixel key = {'21'.toString()+'24'.toString()} color = {"#303030"}/>
    cells[17][24] = <Pixel key = {'17'.toString()+'24'.toString()} color = {"#303030"}/>
  }
  cells = <LoadHat cells = {cells}/>
  return (
      cells
  );
}

export function LoadHat(props) {
  const {currHat, currHairColor} = useDesign()
  var cells = props.cells
  var hat = currHat
  if (hat === "propeller") {
    for (let x = 10; x < 12; x++) {
      for (let y = 17; y < 24; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#f44236"}/>
      }
    }
    for (let y = 18; y < 23; y++) {
      cells[9][y] = <Pixel key = {'9'.toString()+y.toString()} color = {"#f44236"}/>
    }
    for (let y = 19; y < 22; y++) {
      cells[8][y] = <Pixel key = {'8'.toString()+y.toString()} color = {"#f44236"}/>
    }
    cells[7][20] = <Pixel key = {'7'.toString()+'20'.toString()} color = {"#f44236"}/>

    //blue
    cells[7][19] = <Pixel key = {'7'.toString()+'19'.toString()} color = {"#2195f3"}/>

    for (let y = 17; y < 19; y++) {
      cells[8][y] = <Pixel key = {'8'.toString()+y.toString()} color = {"#2195f3"}/>
    }
    for (let y = 16; y < 18; y++) {
      cells[9][y] = <Pixel key = {'9'.toString()+y.toString()} color = {"#2195f3"}/>
    }
    for (let x = 10; x < 12; x++) {
      for (let y = 15; y < 17; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#2195f3"}/>
      }
    }

    //yellow
    cells[7][21] = <Pixel key = {'7'.toString()+'21'.toString()} color = {"#ffeb3b"}/>

    for (let y = 22; y < 24; y++) {
      cells[8][y] = <Pixel key = {'8'.toString()+y.toString()} color = {"#ffeb3b"}/>
    }
    for (let y = 23; y < 25; y++) {
      cells[9][y] = <Pixel key = {'9'.toString()+y.toString()} color = {"#ffeb3b"}/>
    }
    for (let x = 10; x < 12; x++) {
      for (let y = 24; y < 26; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#ffeb3b"}/>
      }
    }
    //propellor
    cells[6][20] = <Pixel key = {'6'.toString()+'20'.toString()} color = {"#000000"}/>
    for (let y = 18; y < 23; y++) {
      cells[5][y] = <Pixel key = {'5'.toString()+y.toString()} color = {"#f44236"}/>
    }
  }

  if (hat == "beanie") {
    for (let x = 10; x < 12; x++) {
      for (let y = 15; y < 26; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#bf360c"}/>
        if (x == 11 & y%2 == 1) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#d84315"}/>
        }
      }
    }
    for (let y = 16; y < 25; y++) {
      cells[9][y] = <Pixel key = {'8'.toString()+y.toString()} color = {"#d84315"}/>
    }
    for (let y = 17; y < 24; y++) {
      cells[8][y] = <Pixel key = {'7'.toString()+y.toString()} color = {"#d84315"}/>
    }
  }
  if (hat == "beanie-pompom") {
    for (let x = 10; x < 12; x++) {
      for (let y = 15; y < 26; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#b3b3b3"}/>
        if (x == 11 & y%2 == 1) {
          cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#f5f5dc"}/>
        }
      }
    }
    for (let y = 16; y < 25; y++) {
      cells[9][y] = <Pixel key = {'8'.toString()+y.toString()} color = {"#f5f5dc"}/>
    }
    for (let y = 17; y < 24; y++) {
      cells[8][y] = <Pixel key = {'7'.toString()+y.toString()} color = {"#f5f5dc"}/>
    }
    for (let x = 6; x < 8; x++) {
      for (let y = 19; y < 22; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#b3b3b3"}/>
      }
    }
  }
  if (hat == "front-cap") {
    for (let y = 15; y < 29; y++) {
      cells[11][y] = <Pixel key = {'10'.toString()+y.toString()} color = {"#f44236"}/>
    }
    for (let x = 9; x < 11; x++) {
      for (let y = 15; y < 25; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#3f51b5"}/>
      }
    }
    for (let y = 16; y < 24; y++) {
      cells[8][y] = <Pixel key = {'7'.toString()+y.toString()} color = {"#3f51b5"}/>
    }
    for (let y = 17; y < 23; y++) {
      cells[7][y] = <Pixel key = {'6'.toString()+y.toString()} color = {"#3f51b5"}/>
    }
    cells[6][19] = <Pixel key = {'5'.toString()+'19'.toString()} color = {"#000000"}/>
  }
  if (hat == "back-cap") {
    for (let y = 11; y < 25; y++) {
      cells[11][y] = <Pixel key = {'11'.toString()+y.toString()} color = {"#f44236"}/>
    }
    for (let x = 9; x < 11; x++) {
      for (let y = 15; y < 25; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#3f51b5"}/>
      }
    }
    for (let y = 16; y < 24; y++) {
      cells[8][y] = <Pixel key = {'7'.toString()+y.toString()} color = {"#3f51b5"}/>
    }
    for (let y = 17; y < 23; y++) {
      cells[7][y] = <Pixel key = {'6'.toString()+y.toString()} color = {"#3f51b5"}/>
    }
    cells[6][19] = <Pixel key = {'5'.toString()+'19'.toString()} color = {"#000000"}/>
  }
  if (hat == "tallhat-black") {
    for (let y = 13; y < 27; y++) {
      cells[11][y] = <Pixel key = {'11'.toString()+y.toString()} color = {"black"}/>
    }
    for (let x = 2; x < 11; x++) {
      for (let y = 15; y < 25; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"black"}/>
      }
    }
    for (let x = 9; x < 11; x++) {
      for (let y = 15; y < 25; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"red"}/>
      }
    }
  }
  if (hat == "tallhat-brown") {
    for (let y = 13; y < 27; y++) {
      cells[11][y] = <Pixel key = {'11'.toString()+y.toString()} color = {"#996600"}/>
    }
    for (let x = 2; x < 11; x++) {
      for (let y = 15; y < 25; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#996600"}/>
      }
    }
    for (let x = 9; x < 11; x++) {
      for (let y = 15; y < 25; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#663300"}/>
      }
    }
  }
  if (hat == "drseuss") {
    for (let y = 13; y < 27; y++) {
      cells[11][y] = <Pixel key = {'11'.toString()+y.toString()} color = {"white"}/>
    }
    for (let x = 9; x < 11; x++) {
      for (let y = 16; y < 25; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#ff3300"}/>
      }
    }
    for (let x = 7; x < 9; x++) {
      for (let y = 15; y < 24; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
      }
    }
    for (let x = 5; x < 7; x++) {
      for (let y = 15; y < 24; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#ff3300"}/>
      }
    }
    for (let x = 3; x < 5; x++) {
      for (let y = 15; y < 25; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"white"}/>
      }
    }
    for (let x = 1; x < 3; x++) {
      for (let y = 16; y < 26; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#ff3300"}/>
      }
    }
  }
  if (hat === "boater") {
    for (let y = 12; y < 28; y++) {
      cells[11][y] = <Pixel key = {'11'.toString()+y.toString()} color = {"#f5f5dc"}/>
    }
    for (let x = 10; x < 11; x++) {
      for (let y = 15; y < 25; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#000066"}/>
      }
    }
    for (let x = 9; x < 10; x++) {
      for (let y = 15; y < 25; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#cc0000"}/>
      }
    }
    for (let x = 8; x < 9; x++) {
      for (let y = 15; y < 25; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#000066"}/>
      }
    }
    for (let x = 6; x < 8; x++) {
      for (let y = 15; y < 25; y++) {
        cells[x][y] = <Pixel key = {x.toString()+y.toString()} color = {"#f5f5dc"}/>
      }
    }
  }
  if (hat === "beret") {
    for (let y = 14; y < 26; y++) {
      cells[11][y] = <Pixel key = {'11'.toString()+y.toString()} color = {"#29293d"}/>
    }
    for (let y = 14; y < 27; y++) {
      cells[10][y] = <Pixel key = {'10'.toString()+y.toString()} color = {"#29293d"}/>
    }
    for (let y = 15; y < 27; y++) {
      cells[9][y] = <Pixel key = {'9'.toString()+y.toString()} color = {"#29293d"}/>
    }
    for (let y = 16; y < 26; y++) {
      cells[8][y] = <Pixel key = {'8'.toString()+y.toString()} color = {"#29293d"}/>
    }
    for (let y = 17; y < 25; y++) {
      cells[7][y] = <Pixel key = {'7'.toString()+y.toString()} color = {"#29293d"}/>
    }
    cells[6][21] = <Pixel key = {'6'.toString()+'21'.toString()} color = {"#29293d"}/>
  }
  if (hat == "comb") {
    if (currHairColor == "000000") {
      cells[8][24] = <Pixel key = {'8'.toString()+'24'.toString()} color = {"#8d6e63"}/>
      for (let y = 23; y < 25; y++) {
        cells[7][y] = <Pixel key = {'7'.toString()+y.toString()} color = {"#8d6e63"}/>
      }
      for (let y = 22; y < 25; y++) {
        cells[6][y] = <Pixel key = {'6'.toString()+y.toString()} color = {"#8d6e63"}/>
      }
      for (let y = 24; y < 26; y++) {
        cells[5][y] = <Pixel key = {'5'.toString()+y.toString()} color = {"#8d6e63"}/>
      }
      for (let y = 25; y < 27; y++) {
        cells[4][y] = <Pixel key = {'4'.toString()+y.toString()} color = {"#8d6e63"}/>
      }
    } else {
      cells[8][24] = <Pixel key = {'8'.toString()+'24'.toString()} color = {"#000000"}/>
      for (let y = 23; y < 25; y++) {
        cells[7][y] = <Pixel key = {'7'.toString()+y.toString()} color = {"#000000"}/>
      }
      for (let y = 22; y < 25; y++) {
        cells[6][y] = <Pixel key = {'6'.toString()+y.toString()} color = {"#000000"}/>
      }
      for (let y = 24; y < 26; y++) {
        cells[5][y] = <Pixel key = {'5'.toString()+y.toString()} color = {"#000000"}/>
      }
      for (let y = 25; y < 27; y++) {
        cells[4][y] = <Pixel key = {'4'.toString()+y.toString()} color = {"#000000"}/>
      }
    }
  }
  return (
      cells
  );
}
