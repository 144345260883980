import React, {useContext, useState, useEffect} from 'react'
import {auth} from '../firebase'
import { getAuth, createUserWithEmailAndPassword,
  signInWithEmailAndPassword, signOut,sendPasswordResetEmail,
  GoogleAuthProvider, signInWithPopup
 } from "firebase/auth"

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({children}) {
  const[currentUser, setCurrentUser] = useState()
  const[loading, setLoading] = useState(true)
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])


  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password)
  }

  function googlelogin() {
    const provider = new GoogleAuthProvider()
    return signInWithPopup(auth, provider)
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  function logout() {
    return signOut(auth)
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email)
  }

  const value = {
    currentUser, signup, login, logout, resetPassword, googlelogin
  }
  return(
    <AuthContext.Provider value = {value}>
      {!loading && children}
    </AuthContext.Provider>

  )
}
