import React from 'react';
import Pixel from './Pixel.js';
import './grid.css';
import Load from './Load.js'
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { hairStyles, skinTones, hairColors, noseStyles, eyeColors, eyeStyles, shirtStyles, mouthStyles, hatStyles, noStyles, backgroundStyles } from "../../StyleData";
import { download} from 'downloadjs';
import {useDesign} from '../../../Context/DesignContext'

export default function Grid(props){
  const {currBackground} = useDesign()
    var cells = [];
    for (var x = 0; x < 36; x++) {
      var row = cells[x] = [];
      for (var y = 0; y < 36; y++) {
        row.push(<Pixel key = {x.toString() + y.toString()} color = {`#${currBackground}`}/>);
      }
    }

    // window.location.href = window.location.href.append("?param=");
    // var currUrl = 'http://localhost:3000/nfme/?param=' + returnEncoded(hairStyles, props.currHairStyle)
    // + returnEncoded(hairColors, props.currHairColor)
    // + returnEncoded(skinTones, props.currSkinTone)
    // + returnEncoded(eyeStyles, props.currEyeStyle)
    // + returnEncoded(eyeColors, props.currEyeColor)
    // + returnEncoded(noseStyles, props.currNose)
    // + returnEncoded(mouthStyles, props.currMouth)
    // + returnEncoded(shirtStyles, props.currShirt)
    // + returnEncoded(hatStyles, props.currHat)
    // + returnEncoded(backgroundStyles, props.currBackground)
    function returnEncoded(array, e) {
      var x = array.findIndex( (name) => name === e.toString());
      if (x < 10) {
        return '0' + x.toString();
      } else {
        return x.toString();
      }
    }

    return (
      <div className = "pixel-grid-container">
        <div className = "pixel-grid" id = "exportblock">
          <Load cells = {cells}
          />
        </div>
      </div>
  );
}
