import React from 'react';
import {HashRouter as Router, Switch, Route} from 'react-router-dom';
import Signup from './Signup'
import Login from './Login'
import Profile from './Profile'
import UserInfo from './UserInfo'
import ForgotPassword from './ForgotPassword'
import PrivateRoute from '../PrivateRoute'
import {AuthProvider} from '../../Context/AuthContext'

export default function Account(){
  return (
    <>
      <Router>
      <AuthProvider>
        <Switch>
          <PrivateRoute path = '/account' component = {Login}/>
          <Route path = '/signup' component = {Signup}/>
          <Route path = '/login' component = {Login}/>
          <Route path = '/forgot-password' component = {ForgotPassword}/>
          <PrivateRoute path = '/profile' component = {Profile}/>
          <PrivateRoute path = '/userinfo' component = {UserInfo}/>
        </Switch>
      </AuthProvider>
      </Router>
    </>
  )
}
