import React , {useRef} from 'react'
import {Link} from 'react-router-dom';

export default function Nav() {
  return (
    <nav>
      <ul className = "nav-links">
        <Link  to='/'>
          <li>Home</li>
        </Link>
        <Link to='/leaderboard'>
          <li>Leaderboard</li>
        </Link>
        <Link to='/profile'>
          <li>My Account</li>
        </Link>
      </ul>
    </nav>
  );
}
