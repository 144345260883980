import React, {useState} from 'react';
// import Collapsible from './Collapsible.js';
// import CustomizationInterface from './Options.js';
import {useDesign} from '../../../Context/DesignContext'
import {Button} from 'react-bootstrap'
import './userinterface.css';
import { hairStyles, skinTones, hairColors, noseStyles, eyeColors, eyeStyles, shirtStyles, shirtColors, mouthStyles, hatStyles, noStyles, backgroundStyles } from "../../StyleData.js";

export function UserInterface(props){
  const {currHairStyle, updateHairStyle, currHairColor, updateHairColor, currSkinTone,
  updateSkinTone, currEyeStyle, updateEyeStyle, currEyeColor, updateEyeColor,
  currNose, updateNose,currMouth, updateMouth, currShirt, updateShirt, currShirtColor, updateShirtColor, currHat, updateHat,
  currBackground, updateBackground, currFace, dummyValue, dummy} = useDesign()
  return (
    <div className = "ui">
    <Collapsible title = "&lt; Hair &gt;" className = "collapsible" children = <CustomizationInterface custom = "hair" handler = {updateHairStyle} handler2 = {updateHairColor} handler3 = {props.setUrl}/>/>
    <Collapsible title = "&lt; Eyes &gt;" className = "collapsible" children = <CustomizationInterface custom = "eyes" handler = {updateEyeStyle} handler2 = {updateEyeColor} handler3 = {props.setUrl}/>/>
    <Collapsible title = "&lt; Nose &gt;" className = "collapsible" children = <CustomizationInterface custom = "nose" handler = {updateNose} handler2 = {dummy} handler3 = {props.setUrl}/>/>
    <Collapsible title = "&lt; Mouth &gt;" className = "collapsible" children = <CustomizationInterface custom = "mouth" handler = {updateMouth} handler2 = {dummy} handler3 = {props.setUrl}/>/>
    <Collapsible key = "skin" title = "&lt; Skin Tone &gt;" className = "collapsible" children = <CustomizationInterface custom = "skin" handler = {dummy} handler2 = {updateSkinTone} handler3 = {props.setUrl}/>/>
    <Collapsible title = "&lt; Shirts &gt;" className = "collapsible" children = <CustomizationInterface custom = "shirt" handler = {updateShirt} handler2 = {updateShirtColor} handler3 = {props.setUrl}/>/>
    <Collapsible title = "&lt; Hat &gt;" className = "collapsible" children = <CustomizationInterface custom = "hat" handler = {updateHat} handler2 = {dummy} handler3 = {props.setUrl}/>/>
    <Collapsible title = "&lt; Background &gt;" className = "collapsible" children = <CustomizationInterface custom = "bg" handler = {dummy} handler2 = {updateBackground} handler3 = {props.setUrl}/>/>
    </div>
  );
}

function Collapsible(props){
  const [open, togglePanel] = useState(false);
  return (<div>
    <Button onClick={()=>togglePanel(!open)} className='collapsible'>
    {props.title}</Button>
    {open ? (
      <div className='content'>
      {props.children}
      </div>
    ) : null}
    </div>);

  }



  function CustomizationInterface(props){
    const {currHairStyle, updateHairStyle, currHairColor, updateHairColor, currSkinTone,
    updateSkinTone, currEyeStyle, updateEyeStyle, currEyeColor, updateEyeColor,
    currNose, updateNose,currMouth, updateMouth, currShirt, updateShirt, currShirtColor, updateShirtColor, currHat, updateHat,
    currBackground, updateBackground, currFace, dummyValue, dummy} = useDesign()
    let optionInput
    let optionInput2
    if (props.custom === "hair") {
      optionInput = hairStyles;
      optionInput2 = hairColors;
    }
    if (props.custom === "eyes") {
      optionInput = eyeStyles;
      optionInput2 = eyeColors;
    }
    if (props.custom === "nose") {
      optionInput = noseStyles;
      optionInput2 = noStyles;
    }
    if (props.custom === "mouth") {
      optionInput = mouthStyles;
      optionInput2 = noStyles;
    }
    if (props.custom === "shirt") {
      optionInput = shirtStyles;
      optionInput2 = shirtColors;
    }
    if (props.custom === "hat") {
      optionInput = hatStyles;
      optionInput2 = noStyles;
    }
    if (props.custom === "bg") {
      optionInput = noStyles;
      optionInput2 = noStyles;
    }
    if (props.custom === "skin") {
      optionInput = noStyles;
      optionInput2 = skinTones;
    }
    if (props.custom === "bg") {
      optionInput = noStyles;
      optionInput2 = backgroundStyles;
    }

    return (
      <div>
      <div className = "option-list">
      {optionInput.map(function(name){
        return <Button className = "option" onClick={() => props.handler(name) }>{name}</Button>;
      })}
      </div>
      <div className = "coloroption-list">
      {optionInput2.map(function(name){
        return <Button className = "color-option" onClick={() => {props.handler2(name)}} style = {{backgroundColor: `#${name}`}}></Button>
      })}
      </div>
      </div>
    );
  }
