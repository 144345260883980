import React from 'react';
import './grid.css';


export default class Pixel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: this.props.color
    };
  }
  changeColor = (newColor) => {
    this.setState({color: newColor});
  }
  render() {
    return (
      <div className = "pixel" style = {{backgroundColor: this.state.color }}>
      </div>
  );
  }
}
