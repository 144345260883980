import React, {useState} from 'react';
import Grid from './Grid/Grid.js';
import { UserInterface} from './UserInterface/UserInterface.js';
import { hairStyles, skinTones, hairColors, noseStyles, eyeColors, eyeStyles, shirtStyles, mouthStyles, hatStyles, noStyles, backgroundStyles } from "../StyleData";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import {Button, Alert} from 'react-bootstrap'
import { download} from 'downloadjs';
import {useAuth} from '../../Context/AuthContext'
import {useDesign} from '../../Context/DesignContext'
import {storage} from '../../firebase'
import { getStorage, ref, uploadString } from "firebase/storage";
import {useHistory} from 'react-router-dom';


export default function Design(){
  var {currHairStyle, updateHairStyle, currHairColor, updateHairColor, currSkinTone,
  updateSkinTone, currEyeStyle, updateEyeStyle, currEyeColor, updateEyeColor,
  currNose, updateNose,currMouth, updateMouth, currShirt, updateShirt,currShirtColor, updateShirtColor, currHat, updateHat,
  currBackground, updateBackground, currFace, dummyValue, dummy} = useDesign()
  const currUrl = window.location.href.split('?param=')[1];
  // let cHS, cHC, cST, cES, cEC, cN, cM, cS, cH, cB
  // if (currUrl && currUrl.length == 20) {
  //     cHS = hairStyles[parseInt(currUrl.substring(0,2))];
  //     cHC = hairColors[parseInt(currUrl.substring(2,4))]
  //     cST = skinTones[parseInt(currUrl.substring(4,6))]
  //     cES = eyeStyles[parseInt(currUrl.substring(6,8))]
  //     cEC = eyeColors[parseInt(currUrl.substring(8,10))]
  //     cN = noseStyles[parseInt(currUrl.substring(10,12))]
  //     cM = mouthStyles[parseInt(currUrl.substring(12,14))]
  //     cS = shirtStyles[parseInt(currUrl.substring(14,16))]
  //     cH = hatStyles[parseInt(currUrl.substring(16,18))]
  //   } else {
  //     cHS = hairStyles[Math.floor(Math.random() * hairStyles.length)];
  //     cHC = hairColors[Math.floor(Math.random() * hairColors.length)];
  //     cST = skinTones[Math.floor(Math.random() * skinTones.length)];
  //     cES = eyeStyles[Math.floor(Math.random() * eyeStyles.length)];
  //     cEC = eyeColors[Math.floor(Math.random() * eyeColors.length)];
  //     cN = noseStyles[Math.floor(Math.random() * noseStyles.length)];
  //     cM = mouthStyles[Math.floor(Math.random() * mouthStyles.length)];
  //     cS = shirtStyles[Math.floor(Math.random() * shirtStyles.length)];
  //     cH = hatStyles[Math.floor(Math.random() * (hatStyles.length-1))];
  //   }
  //
  //   var [currHairStyle, setHairStyle] = useState(cHS);
  //   var [currHairColor, setHairColor] = useState(cHC);
  //   var [currEyeStyle, setEyeStyle] = useState(cES);
  //   var [currEyeColor, setEyeColor] = useState(cEC);
  //   var [currNose, setNose] = useState(cN);
  //   var [currMouth, setMouth] = useState(cM);
  //   var [currShirt, setShirt] = useState(cS);
  //   var [currHat, setHat] = useState(cH);
  //   var [currFace, setFace] = useState("normal");
  //   var [dummyValue, setDummy] = useState(0);
    var [error, setError] = useState('');
    const history = useHistory()
    const {currentUser} = useAuth()
  const possiblePermutations = hairStyles.length*hairColors.length*skinTones.length*eyeStyles.length*eyeColors.length*noseStyles.length*mouthStyles.length*shirtStyles.length*hatStyles.length*backgroundStyles.length
  async function handleExport(e) {
    setError('')
    if (currentUser) {
      htmlToImage.toJpeg(document.getElementById('exportblock'), { quality: 1 }).then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'my-nfme.jpeg';
        link.href = dataUrl;
        var img = link.click();
        console.log(link.href);
        const storageRef = ref(storage, 'base64/'+currentUser.uid);
        uploadString(storageRef, link.href, 'data_url').then((snapshot) => {
          console.log('Uploaded a data_url string!');
        });
      })
    } else {
      history.push('/signup')
      setError('Create an account to continue')
    }
  }

  function ExportButton(props) {
    var buttontxt = 'Continue with my NFME >'
    if (currentUser) {
      buttontxt = 'Save my current NFME'
    }
      return(
        <div className = "exportContainer">
          <Button className = "export" onClick = {() => handleExport()
          }>{buttontxt}</Button>
        </div>
      );
  }


  return(
    <>
    <div className = "app">
    <div className = "left">
    <Grid
    key = {currSkinTone + currHairStyle + currHairColor + currSkinTone + currEyeStyle + currEyeColor
      + currNose + currMouth + currShirt + currHat + currBackground + currFace + currShirtColor
    }
    />
    </div>
    <div className = "right">
    <UserInterface
    />
    </div>
    </div>
    <ExportButton/>
    {error && <Alert className = "export-alert" variant = 'danger'>{error}</Alert>}
    </>
  );
}
