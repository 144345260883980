import React , {useRef, useState} from 'react'
import {CardGroup, Card, Form, Button, Alert, ButtonGroup} from 'react-bootstrap'
import {useAuth} from '../../Context/AuthContext'
import {Link, useHistory} from 'react-router-dom'
import Profile from './Profile'
import {storage, database} from '../../firebase'
import { getDatabase, ref, set } from "firebase/database";


export default function UserInfo() {
  var nameRef = useRef();
  var IGRef = useRef();
  // var yesRef = useRef();
  const [loading, setLoading] = useState(false);
  var Filter = require('bad-words');
  var customFilter = new Filter({ placeHolder: ''});
  const {currentUser} = useAuth()
  const [error, setError] = useState('')

  function handleSubmit(e) {
    e.preventDefault()
    set(ref(database, 'users/' + currentUser.uid), {
      name: nameRef.currentvalue ? customFilter.clean(nameRef.current.value) : nameRef.current.value,
      social: IGRef.current.value ? customFilter.clean(IGRef.current.value) : nameRef.current.value,
  });
  }


  return (
    <>
      <Card>
        <Card.Body className = "signup-container">
          <Card.Title className = "signup-title">Update my Profile</Card.Title>
          <Form onSubmit = {handleSubmit}>
            <Form.Group id = "name">
              <Form.Label>Name</Form.Label>
              <Form.Control type = "text" ref = {nameRef}/>
            </Form.Group>
            <Form.Group id = "social">
              <Form.Label>IG Handle</Form.Label>
              <Form.Control type = "text" ref = {IGRef}/>
            </Form.Group>
            <div className = "submit-container">
              <Button disabled = {loading} type = "submit" className = "signup-button">UPDATE INFO</Button>
            </div>
          </Form>
          <div className = 'update-link'><Link className = "redirect" to = '/profile'>Go back to Profile</Link></div>
        </Card.Body>
      </Card>
    </>
  );
}

// <Form.Group id = "display">
//   <Form.Label>Display information publicly:</Form.Label>
//   <div className="radio">
//   <Form.Check
//     ref = {yesRef}
//     inline
//     label=" Yes"
//     name="group1"
//     type="radio"
//     className = 'radio-option'
//   />
//   <Form.Check
//     inline
//     label=" No"
//     name="group1"
//     type="radio"
//     className = 'radio-option'
//   />
//   </div>
// </Form.Group>
