import React , {useRef} from 'react'
import { getStorage, ref, listAll, list, getDownloadURL, getMetadata } from "firebase/storage"
import ReactDOM from "react-dom"
import { uniqueNamesGenerator, Config, adjectives, colors, animals } from 'unique-names-generator';
import $ from 'jquery';

export default function LeaderBoard() {
  const storage = getStorage();
  const listRef = ref(storage, 'base64/');


  var i = 0;
  async function tableSelection() {
    var new_tbody = document.createElement('tbody');
    new_tbody.id = 'oldtbody';
    var old_tbody = document.getElementById('oldtbody');
    if (old_tbody) {
      old_tbody.parentNode.replaceChild(new_tbody, old_tbody);
    }
    const firstPage = await list(listRef, { maxResults: 20 });
    firstPage.items.forEach((itemRef) => {
      i++;
      const randomName = uniqueNamesGenerator({ dictionaries: [adjectives, colors, animals], separator: " "});
      displayImage(i, itemRef, randomName)
    });
  }
  function displayImage(row, images, name) {
    getDownloadURL(images)
    .then((url) => {
      console.log(url);
      let new_html = ''
      new_html+='<tr>'
      new_html+='<td>'
      new_html+= '<p class = '+ `'`+ 'table-number'+ `'>`+'</p>'
      new_html+='</td>'
      new_html+='<td>'
      new_html+= '<img class = '+ `'`+ 'table-img'+ `'`+'src = '+url+'>'
      new_html+='</td>'
      new_html+='<td>'
      new_html+= '<p class = '+ `'`+ 'table-number'+ `'>`+name+'</p>'
      new_html+='</td>'
      new_html+='</tr>'
      $('#img-list').find('tbody').append(new_html);
    })
  }

  tableSelection();



  return (
    <>
    <h2 className = "leaderboard">Leaderboard</h2>
    <div className = "list-container">
      <table id = "img-list">
        <tbody id = 'oldtbody'>
        </tbody>
      </table>
    </div>
    </>
  );
}
