import React, {useState} from 'react';
import Grid from './Design/Grid/Grid.js';
import { UserInterface, ExportButton} from './Design/UserInterface/UserInterface.js';
import { hairStyles, skinTones, hairColors, noseStyles, eyeColors, eyeStyles, shirtStyles, mouthStyles, hatStyles, noStyles, backgroundStyles } from "./StyleData";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { download} from 'downloadjs';
import Account from './UserAccount/Account'
import Design from './Design/Design.js'
import Leaderboard from './Leaderboard/Leaderboard.js'
import Nav from './Nav.js'
import {HashRouter as Router, Switch, Route} from 'react-router-dom';
import {AuthProvider} from '../Context/AuthContext'
import {DesignProvider} from '../Context/DesignContext'
import PrivateRoute from './PrivateRoute'

export function App(){
  const possiblePermutations = hairStyles.length*hairColors.length*skinTones.length*eyeStyles.length*eyeColors.length*noseStyles.length*mouthStyles.length*shirtStyles.length*hatStyles.length*backgroundStyles.length
  return(
    <AuthProvider>
    <DesignProvider>
    <Router>
      <div className = "wrapper">
      <p className="headline animated">
      {possiblePermutations.toLocaleString()} possible NF-MEs. What will yours be?
      </p>
      <h1 className = "title">
      &lt;NF-ME&gt;
      </h1>
      <p>Join our NFME community</p>
      <Nav/>
      <div>
      </div>
      <hr/>
      <div className = "spacer"></div>
      <Switch>
        <Route path = "/" exact component = {Design}/>
        <Route path = "/leaderboard" component = {Leaderboard}/>
        <Route path = "/userinfo" component = {Account}/>
        <Route path = "/profile" component = {Account}/>
        <Route path = '/signup' component = {Account}/>
        <Route path = '/login' component = {Account}/>
        <Route path = '/forgot-password' component = {Account}/>
        <Route path = "/*" exact component = {Design}/>
      </Switch>
      <div className = "spacer"></div>
      <div className = "spacer"></div>
      <footer><p>Issues or Suggestions? Shoot an email to team@letsnft.me</p></footer>
      </div>
    </Router>
    </DesignProvider>
    </AuthProvider>

  );
};
