import React , {useRef, useState} from 'react'
import {CardGroup, Card, Form, Button, Alert} from 'react-bootstrap'
import {useAuth} from '../../Context/AuthContext'
import {Link, useHistory} from 'react-router-dom'
import Profile from './Profile'

export default function ForgotPassword() {
  const emailRef = useRef()
  const { resetPassword } = useAuth()
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setMessage('')
      setError('')
      setLoading(true)
      await resetPassword(emailRef.current.value)
      setMessage('Check your inbox to reset your password')
    } catch {
      return setError(`Account with email ${emailRef.current.value} not found`)
    }
    setLoading(false)
  }

  return (
    <>
      <Card>
        <Card.Body className = "signup-container">
          <Card.Title className = "signup-title">Password Reset</Card.Title>
          {error && <Alert className = "alert" variant = 'danger'>{error}</Alert>}
          {message && <Alert className = "success" variant = 'success'>{message}</Alert>}
          <Form onSubmit = {handleSubmit}>
            <Form.Group id = "email">
              <Form.Control type = "email" ref = {emailRef} required placeholder = "Email"/>
            </Form.Group>
            <div className = "submit-container">
              <Button disabled = {loading} type = "submit" className = "signup-button">RESET PASSWORD</Button>
            </div>
          </Form>
          <div>Back to <Link className = "redirect" to = '/login'>Login</Link></div>
        </Card.Body>
      </Card>
    </>
  );
}
