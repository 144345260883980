
export const curl4Pixels = [
  [18,26],
  [12,18],
  [12,23],
  [12,30],
  [15,9]
];

export const curl4Pixels2 = [
  [17,28],
  [18,28],
  [19,28],
  [20,28],
  [21,28],
  [16,29],
  [17,29],
  [15,30],
  [14,30],
  [13,30],
  [16,30],
  [17,30],
  [18,30],
  [19,30],
  [19,26],
  [20,26],
  [21,26],
  [22,25],
  [22,26],
  [23,26],
  [24,23],
  [24,21],
  [21,13],
  [17,11]
];

export const curl1Pixels2 = [
  [8,18],
  [8,21],
  [8,22],
  [12,22],
  [12,14],
  [12,26],
  [10,15],
  [9,15],
  [10,14]
]

export const curl3Pixels = [
  [13,14],
  [11,12],
  [9,12],
  [7,12],
  [4,18],
  [4,20],
  [5,24],
  [7,27],
  [9,27],
  [11,27],
  [7,14],
  [7,24],
  [7,25],
  [6,25],
  [5,17],
  [5,18],
  [5,19],
  [5,21],
  [5,22]
]
export const curl2Pixels = [
  [11,13],
  [12,13],
  [7,18],
  [8,23],
  [11,27]
]

export const sidePartPixels = [
  [9,24],
  [13,16],
  [12,17],
  [12,26]
]


export const wavyShortHairPixels = [
  [21,8],
  [22,11],
  [22,12],
  [23,11],
  [22,27],
  [20,29],
  [14,15]
]
