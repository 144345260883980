export const hairStyles = ["baldhead", "buzzcut", "sidepart", "middlepart",
"bun", "high-ponytail-1","high-ponytail-2", "right-longhair",
"left-longhair", "longhair","shoulder-longhair","wavy-longhair",
"shorthair","wavy-shorthair","tall-afro", "afro", "1-curl",
"1-curl-2", "2-curl", "3-curl", "4-curl", "4-curl-2", "mohawk"
];

export const hairColors = ["FFFFFF","D3D3D3","F0E2B6","f4dd6c", "FFCC47",
 "cc9933","CC9966", "9C5B33", "9a3300", "5A3825","4f1a00",
 "241c11", "000000","666666","3366ff","1592CA", "34BFF0",
 "eca1ac", "e27589", "b25b6e", "7c3042", "caec54",
 "93b359", "009933", "DB8148", "cc5200", "ff6600", "cc3300"
];

export const noseStyles = ["dot", "v-line", "h-line", "nostril", "clown"];

export const eyeStyles = ["left", "left-eyebags", "right", "right-eyebags",
"glasses-red", "glasses-brown", "sunglasses", "eyepatch", "laserbeam","cyclops"];

export const eyeColors = ["000000", "451800","663300", "634e34", "9e6b4a", "2e536f",
"3d671d", "1c7847", "357388", "ADD8E6", "ff0000", "ff99ff", "cc00ff","9999ff", "00ffff", "0099ff", "009999", "00ff99","33cc33"];

export const mouthStyles = ["line-short","line-long", "smirk", "stache","olips", "olips-2","purple-lipgloss","green-lipgloss","blue-lipgloss", "joint", "pipe", "facemask1", "facemask2", "tongue", "tongue-2", "vampire"];

export const skinTones = ["472601", "603301", "795548", "8d5524",
"93786A","B06C49", "c68642", "fca039", "fdbb72", "e0ac69",
"E8BEAC", "f1c27d", "ffdbac", "fee9d2", "b3d9ff", "339966",
"669900", "e6e6ff","99ccff", "9999ff", "ff6666", "ff9933"];

var shirtStyles = ["harv", "penn", "prin", "corn", "dart",
 "berk", "colu", "brown", "stan", "mit", "cmu", "ucla", "uiuc", "umich", "a&m", "duke", "uva", "nyu", "utaust", "usc"];

 shirtStyles.sort();
 shirtStyles = ["yale"].concat(shirtStyles)
  shirtStyles = shirtStyles.concat(["color"])
  shirtStyles = ["athelas"].concat(shirtStyles)
 export var shirtStyles

 export const shirtColors = ["000000", "ff0000", "ff6600", "ff9966", "ffcc00", "ffff00", "ccff33", "66ff33",
"33cc33","00cc66","009999", "006699","0066cc","0066ff","3366ff","3333ff", "3333cc", "6600ff", "9933ff", "cc33ff"];

export const hatStyles = ["none","propeller", "beanie", "beanie-pompom", "front-cap", "back-cap", "tallhat-black", "tallhat-brown", "drseuss", "boater", "beret", "comb"];

export const backgroundStyles = ["BAC1EC", "E6D7F0","B6D7F0", "B9F0E0","D0E5B9", "FEF7E7", "EC94B7", "FAB3A7"
,"FCDDAF", "C2ACA1", "856B61", "55586B", "6E7894", "8CB3B1"
]

export const noStyles = [];
