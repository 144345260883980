import React, {useContext, useState, useEffect} from 'react'
import { hairStyles, skinTones, hairColors, noseStyles, eyeColors, eyeStyles, shirtStyles, shirtColors, mouthStyles, hatStyles, noStyles, backgroundStyles } from "../App/StyleData.js";

const DesignContext = React.createContext()

export function useDesign() {
  return useContext(DesignContext)
}

export function DesignProvider({children}) {
  let cHS = hairStyles[Math.floor(Math.random() * hairStyles.length)];
  let cHC = hairColors[Math.floor(Math.random() * hairColors.length)];
  let cST = skinTones[Math.floor(Math.random() * skinTones.length)];
  let cES = eyeStyles[Math.floor(Math.random() * eyeStyles.length)];
  let cEC = eyeColors[Math.floor(Math.random() * eyeColors.length)];
  let cN = noseStyles[Math.floor(Math.random() * noseStyles.length)];
  let cM = mouthStyles[Math.floor(Math.random() * mouthStyles.length)];
  let cS = shirtStyles[0]
  // let cS = shirtStyles[Math.floor(Math.random() * shirtStyles.length)];
  let cSC = shirtColors[Math.floor(Math.random() * shirtColors.length)];
  let cH = hatStyles[Math.floor(Math.random() * (hatStyles.length-1))];
  let cB = backgroundStyles[Math.floor(Math.random() * backgroundStyles.length)];
  var [currHairStyle, setHairStyle] = useState(cHS);
  var [currHairColor, setHairColor] = useState(cHC);
  var [currSkinTone, setSkinTone] = useState(cST);
  var [currEyeStyle, setEyeStyle] = useState(cES);
  var [currEyeColor, setEyeColor] = useState(cEC);
  var [currNose, setNose] = useState(cN);
  var [currMouth, setMouth] = useState(cM);
  var [currShirt, setShirt] = useState(cS);
  var [currShirtColor, setShirtColor] = useState(cSC);
  var [currHat, setHat] = useState(cH);
  var [currBackground, setBackground] = useState(cB);
  var [currFace, setFace] = useState("normal");
  var [dummyValue, setDummy] = useState(0);

  function updateHairStyle(hair) {
    setHairStyle(hair)
  }
  function updateHairColor(hair) {
    setHairColor(hair)
  }
  function updateSkinTone(skin) {
    setSkinTone(skin)
  }
  function updateEyeStyle(eye) {
    setEyeStyle(eye)
  }
  function updateEyeColor(eye) {
    setEyeColor(eye)
  }
  function updateNose(nose) {
    setNose(nose)
  }
  function updateMouth(mouth) {
    setMouth(mouth)
  }
  function updateShirt(shirt) {
    setShirt(shirt)
  }
  function updateShirtColor(shirtColor) {
    setShirtColor(shirtColor)
  }
  function updateHat(hat) {
    setHat(hat)
  }
  function updateBackground(background) {
    setBackground(background)
  }
  function dummy() {
  }

  var value = {
    currHairStyle, updateHairStyle, currHairColor, updateHairColor, currSkinTone,
    updateSkinTone, currEyeStyle, updateEyeStyle, currEyeColor, updateEyeColor,
    currNose, updateNose,currMouth, updateMouth, currShirt, updateShirt, currShirtColor, updateShirtColor,
    currHat, updateHat, currBackground, updateBackground, currFace, dummyValue, dummy
  }
  return(
    <DesignContext.Provider value = {value}>
      {children}
    </DesignContext.Provider>

  )
}
